<script>
import vueFilePond from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import config from "../helpers/config";

// Import the plugin styles

// Register the plugin
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
  components: {
    FilePond,
  },
  data() {
    return {
      form: {
        title: "",
      },
      files: [],
    };
  },
  methods: {
    handlePondInit() {
      var self = this;
       this.$refs.pond._pond.setOptions({
        server: {
          process: function(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
          
            $.ajax({
              url: config.urlToApi + 'images/',
              type: 'post',
              data: formData,
              processData: false,  // Important!
              contentType: false,  // Important!
              xhr: function() {
                var xhr = new window.XMLHttpRequest();               
                xhr.upload.addEventListener("progress", function(evt) {
                  if (evt.lengthComputable) {
                    progress(evt.lengthComputable, evt.loaded, evt.total);
                  }
                }, false);
                return xhr;
              },
              beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.samfallifyToken);
              },
              success: function(response) {
                load(response.filepath);
              },
              error: function(xhr, status, err) {
                if (status === 'abort') {
                  console.log('Request canceled', err);
                  abort();
                } else {
                  error('oh no!');
                }
              },
            });
          },
          revert: null,
          restore: null,
          load: null,
          fetch: null,
        },
      });
      
    }
  },
};
</script>
<template>
  <h2>Ladda upp bilder till bildbanken</h2>
  <file-pond
    ref="pond"
    name="file"
    allow-multiple="true" 
    max-files="5"
    max-file-size="4MB"
    allowImagePreview="true"
    imagePreviewMaxHeight="150"
    label-idle="Dra och släpp dina filer här eller <span class='filepond--label-action'> Bläddra </span> (Max 10 filer åt gången. Maximal filstorlek: 3 mb)"
    :files="files" 
    :allowRevert="false"
    accepted-file-types="image/png, image/jpeg, image/gif"         
    @init="handlePondInit"
  />
</template>
<style scoped>
.filepond--credits
{
  display: none !important;
}
</style>
  