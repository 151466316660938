<script>
import { stringify } from 'querystring-es3'
import AdminLink from '../components/AdminLink.vue'
import List from '../components/List/List.vue'
export default {
    components:{ AdminLink, List },
    created() {     
        this.$cache.createCache('workGroups', 'work-groups/') 
    },
    data() {
        return { 
            columns: [                       
                { name: 'name', displayName: 'Namn på ' + this.$store.state.settings.WorkGroup.singular, width: 270, hideFilter: true},
                { name: 'description', displayName: 'Beskrivning', width: 600, hideFilter: true},             
                { name: 'members', displayName: 'Personer', width: 1000, hideFilter: true},             
            ]
        }
    },
    computed: {
        workGroups() {  return this.$store.state.workGroups != null ? this.$store.state.workGroups.map(x => ( {
            name: x.name,
            members: x.members.map(y => y.user.id == this.user.id ? 'Du' : y.user.full_name).join(", "),
            description: x.description
        })) : []; },
        user() {return this.$store.state.user; }
    }
}
</script>
<template>
    <div class="overflow-auto">
        <AdminLink :buttonText="'Lägg till/ändra ' + this.$store.state.settings.WorkGroup.plural" :sectionToEdit="'adminWorkGroups'"></AdminLink>
        <List :items="workGroups" :columns="columns" :nameOfItems="this.$store.state.settings.WorkGroup.plural" :noOfFrozenColumns="1"></List>    
    </div>       
</template>