<script>
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import SelectImage from '@/views/Admin/Public/SelectImage.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import ColorPicker from '@/components/ColorPicker.vue'
import Switch from '@/components/Form/Switch.vue'
import TextEditor from '@/components/TextEditor.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"
import  { Sketch }  from '@ckpack/vue-color'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ Form, PopUp, ToAdmin, Switch, Sketch, ColorPicker, UserInstruction, TextEditor, SelectImage },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 

        this.$cache.createCache('accountSettings', 'account-settings/');
        this.$cache.createCache('availableBookings', 'available-bookings/');
        this.$cache.createCache('notifications', 'notifications/');
        this.$cache.createCache('boardMembers', 'board-members/');
        this.$cache.createCache('workGroups', 'work-groups/');
        this.$cache.createCache('statutes', 'statutes/');
        this.$cache.createCache('rulesOfProcedure', 'rules-of-procedure/');
        this.$cache.createCache('images', 'images/');
        this.getAccountSettings();
       
    },
    data() {
        return {  
            selectedTab: 'hem', 
            publicPage: null,
            publicPageHome: null,
            publicPageCommunity: null,
            publicPageImages: null,
            colors: null,
            isLoading: true,
            accountSettings: {},
            editBg: null,
            highlightedImage: null,
            hasChanges: false,
            bookingItem: null,
            showBookings: false,
            refPublicPage: {},
            refPublicPageHome: {},
            refPublicPageCommunity: {},
            refPublicPageImages: {},
            refCustomTabs: [],
            inAddTabMode: false,
            tabName: null,
            customTabs: [],
            selectedCustomTab: null,
            editTab: null,
            reloadPreview: false
        }
    }, 
    methods:
    {
        edit()
        {
        }, 
        getAccountSettings()
        {
            var self = this;
            this.$communicator.get('account-settings/')
                .then(function(data)
                {
                    self.accountSettings = data;
                    if(data.enable_public_page)
                    {
                        self.getPublicPage();
                    } else
                    {
                        self.isLoading = false;
                    }
                })
                .fail(function(error) {
                    
                });
        },      
        getPublicPage()
        {
            var self = this;
            this.$communicator.get('admin-public-page/')
                .then(function(data)
                {
                    self.publicPage = data.public_page;

                    for (let prop in data.public_page) {
                        self.refPublicPage[prop] = data.public_page[prop];
                    }

                    
                    self.publicPageHome = data.public_page_home;  
                    for (let prop in data.public_page_home) {
                        self.refPublicPageHome[prop] = data.public_page_home[prop];
                    }      

                    self.publicPageCommunity = data.public_page_community;
                    for (let prop in data.public_page_community) {
                        self.refPublicPageCommunity[prop] = data.public_page_community[prop];
                    }  
                    
                    self.customTabs = [];
                    self.customTabs = data.tabs;
                    self.refCustomTabs = [];
                    data.tabs.forEach(function(tab) {
                        var refTab = {};
                        for (let prop in tab) {
                            refTab[prop] = tab[prop];
                        }
                        self.refCustomTabs.push(refTab);       
                    });
                    
                    self.publicPageImages = [];
                    self.publicPageImages = data.public_page_images;
                    self.refPublicPageImages = [];
                    data.public_page_images.forEach(function(img) {
                        var refImg = {};
                        for (let prop in img) {
                            refImg[prop] = img[prop];
                        }
                        self.refPublicPageImages.push(refImg);       
                    });

                    if(self.selectedCustomTab != null) {

                        self.selectedCustomTab = self.customTabs.find(x => x.id == self.selectedCustomTab.id);
                    }

                    self.colors = self.publicPage.background_color;
                    self.isLoading = false;    
                    self.reloadPreview = false;              
                })
                .fail(function(error) {
                    
                });
        },
        addTab(){
            var self = this;
            this.$communicator.post('admin-public-page-add-custom-tab/', { name: this.tabName })
                .then(function(data)
                {
                    self.inAddTabMode = false;
                    message.setSuccessMessage("Din flik är tillagd!");
                    self.getPublicPage();
                })
                .fail(function(error) {
                    
                });
        },
        selectCustomTab(tab)
        {
            this.selectedCustomTab = tab;           
            this.selectedTab = 'customTab';
        },
        saveEnablePublicPage()
        {
            var self = this;
            this.$communicator.post('admin-enable-public-page/')
                .then(function(data)
                {
                    self.getAccountSettings();

                    if(data.enable_public_page)
                    {
                        message.setSuccessMessage("Grattis! Nu är er publika sida aktiverad!")
                    }
                    else {
                        message.setSuccessMessage("Er publika sida är nu inaktiverad!")
                    }

                })
                .fail(function(error) {
                    
                });
        },
        removeTab(id)
        {
            var self = this;
            this.$communicator.post('admin-public-page-remove-tab/' + id)
                .then(function(data)
                {
                    self.getAccountSettings();

                    
                    message.setSuccessMessage("Fliken är borttagen!")
                    

                })
                .fail(function(error) {
                    
                });
        },
        saveAndPublishPublicPage()
        {
            var self = this;
            this.reloadPreview = true;
            var data = { 
                public_page: this.publicPage,
                 public_page_community: this.publicPageCommunity, 
                 public_page_home: this.publicPageHome, 
                 available_bookings: this.availableBookings, 
                 tabs: this.customTabs, 
                 public_page_images: this.publicPageImages  
            };

            this.$communicator.post('admin-public-page/', data)
                .then(function(data)
                {
                    self.getPublicPage();
                    self.hasChanges = false;
                    message.setSuccessMessage("Toppen! Era ändringarna är nu publicerade!");
                    self.$cache.updateCache('availableBookings', 'available-bookings/');                   
                })
                .fail(function(error) {
                    
                });
        },
        selectBackgroundImage(image)
        {
        
            this[this.editBg.model][this.editBg.prop] = image.name;
            this[this.editBg.model][this.editBg.id] = image.id;
            this.editBg = null;
        },       
        checkPropChange(newValue, referenceObject)
        {
            if(referenceObject == undefined)
            {   
                this.hasChanges = true;
            } 
            else if(newValue != null)
            {
                for (const property in newValue) {
                    if(property != 'hasChanges' && newValue[property] != referenceObject[property])
                    {                  
                        this.hasChanges = true;
                    }                   
                }
            }      
        },
        removeLogo()
        {
            this.publicPage.logo_image_id = null;
            this.publicPage.logo_image_name = null;
        },
        showBooking(bookingItem)
        {
            this.bookingItem = bookingItem;
            this.selectedTab = 'bokning';
            this.showBookings = false;

        },
        getBackgroundImage(location)
        {
            var img = this.publicPageImages.find(x => x.location == location);
            if(img == undefined)
                return '';


            return  { 
                'background-image': 'url(' + this.url + 'uploads/'+ this.customer.id+'/images/'+ img.image_name,
                'background-position': img.position
             };
        }
    },
    computed:
    {
        customer() { return this.$store.state.customer }, 
        user() {return this.$store.state.user; },
        availableBookings() {return this.$store.state.availableBookings == null ? [] : this.$store.state.availableBookings.filter(x => x.include_in_public_site); },
        notifications() { return this.$store.state.notifications != null ? this.$store.state.notifications.filter(x => x.include_in_public_site) : [] },
        boardMembers() { return this.$store.state.boardMembers != null ? this.$store.state.boardMembers : [] },
        workGroups() {   return this.$store.state.workGroups != undefined ? this.$store.state.workGroups : [] },
        statutes() {  return this.$store.state.statutes != null ? this.$store.state.statutes : []  },
        rulesOfProcedure() {  return this.$store.state.rulesOfProcedure != null ? this.$store.state.rulesOfProcedure : []   },  
        images() {  return this.$store.state.images != null ? this.$store.state.images : []  },  
        url() { return config.urlToServer },              
    },
    watch:
    {
        publicPage: {
            handler(newValue){  
                this.hasChanges = false;           
                this.checkPropChange(newValue, this.refPublicPage);   
            },
            deep: true
        },
        publicPageCommunity: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                this.checkPropChange(newValue, this.refPublicPageCommunity);
            },
            deep: true
        },
        publicPageHome: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                this.checkPropChange(newValue, this.refPublicPageHome);
            },
            deep: true
        },
        customTabs: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                for(var i = 0; i < newValue.length; i++)
                    this.checkPropChange(newValue[i], this.refCustomTabs[i]);          
            },
            deep: true
        },
        publicPageImages: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                for(var i = 0; i < newValue.length; i++)
                        this.checkPropChange(newValue[i], this.refPublicPageImages[i]);       
            },
            deep: true
        },
        selectedTab(newvalue)
        {
            if(newvalue != 'bokning')
            {
                this.showBookings = false;
            }
        }
    }
}
</script>
<template>
    <div v-fade-in v-if="!isLoading">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">              
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Publika Sidan'"></ToAdmin>
            <div v-if="accountSettings.enable_public_page && publicPage != null">    
                <div class="g-40">
                    <div style="padding-right: 15px;">
                        <div v-if="selectedTab == 'hem'">
                            <h2>Flikar</h2>
                            <div class="margin-bottom-25" style="line-height: 36px;">                  
                                    <Switch :model="publicPage" :prop="'include_image_gallery'"></Switch>
                                    &nbsp; Visa bildgalleriet (gå till bildbanken för att välja vilka bilder som ska visas). <br>
                                    <Switch :model="publicPage" :prop="'include_contact'"></Switch>
                                    &nbsp; Visa en kontakt-flik.  
                                    
                                    <div>
                                        <div v-for="tab in customTabs" class="AdminPublic-custom-tabs" :style="{ 'background-color': publicPage.background_color }">
                                            <div v-if="editTab != null">
                                                <input v-model="editTab.name"/>
                                                <button class="btn" @click="editTab = null">Klar</button>
                                            </div>
                                            <div v-if="editTab == null">
                                                <span>{{ tab.name }} &nbsp; </span> 
                                                <i class="fa-solid fa-pencil" v-tooltip="'Byt namn på fliken'" @click="editTab = tab"></i> &nbsp; 
                                                <i class="fa-solid fa-trash" @click="removeTab(tab.id)" v-tooltip="'Ta bort fliken'"></i>
                                            </div>
                                        </div>
                                        <div v-if="customTabs.length < 3">
                                            <button v-if="!inAddTabMode" class="btn" @click="inAddTabMode = true">Lägg till en flik</button>  
                                            <div v-if="inAddTabMode">
                                                <input type="text" style="width: 30%" v-model="tabName" placeholder="Namn på fliken">&nbsp;
                                                <button class="btn" @click="addTab">Spara</button>  
                                            </div>
                                        </div>
                                        <div v-else class="info"><b>Du kan inte lägga till flera flikar än 3. Ta bort en flik om du vill lägga till en ny</b></div>
                                    </div>
                                
                            </div> 
                            <h2>Sidans färger</h2>
                            <div>
                                <div class="margin-top-15">
                                    <table class="AdminPublic-colors">
                                        <tr>
                                            <td>Bakgrundsfärg</td>
                                            <td>Rubrikfärg</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <ColorPicker v-model:color="publicPage.background_color"></ColorPicker>
                                            </td>
                                            <td>
                                                <ColorPicker v-model:color="publicPage.header_color"></ColorPicker>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>   
                            <h2>Skriv en välkomsttext</h2>
                            <TextEditor :model="publicPageHome" :prop="'welcome_text'" :height="'500px'"></TextEditor>                           
                        </div>
                        <div v-if="selectedTab == 'foreningen'">
                            
                            <div class="AdminPublic-edit-section">
                                <h2>Skriv en beskrivande text om föreningen. Om du inte vill ha någon text kan du lämna rutan tom.</h2> 
                                <TextEditor :model="publicPageCommunity" :prop="'community_text'"  :height="'500px'"></TextEditor>                              
                            </div>
                            <div class="AdminPublic-edit-section">
                                <h4>Välj vilka delar av er förening som ska visas på den publika sidan</h4> 
                                <table>
                                    <tr>
                                        <td width="60"><Switch :model="publicPageCommunity" :prop="'show_board_members'"></Switch></td>
                                        <td>Visa {{ this.$store.state.settings.BoardMember.singular }}</td>
                                    </tr>
                                    <tr>
                                        <td width="60"><Switch :model="publicPageCommunity" :prop="'show_work_groups'"></Switch></td>
                                        <td>Visa {{this.$store.state.settings.WorkGroup.plural}}</td>
                                    </tr>
                                    <tr>
                                        <td width="60"><Switch :model="publicPageCommunity" :prop="'show_statutes'"></Switch></td>
                                        <td>Visa {{this.$store.state.settings.Statute.plural}}</td>
                                    </tr>
                                    <tr>
                                        <td width="60"><Switch :model="publicPageCommunity" :prop="'show_rules_of_procedure'"></Switch></td>
                                        <td>Visa {{this.$store.state.settings.RuleOfProcedure.plural}}</td>
                                    </tr>
                                </table>       
                            </div>
                        </div>
                        <div v-if="selectedTab == 'bildgalleriet'">
                            <div class="padding-15">
                                <h2>Bildgalleriet</h2>
                                De bilder som ska visas väljs i <router-link :to="{ name: 'adminImageVault' }">bildbanken</router-link> 
                            </div>
                        </div>
                        <div v-if="selectedTab == 'kontakt'">
                            <div class="padding-15">
                                <div class="AdminPublic-edit-section">
                                <h2>Beskriv hur ni vill bli kontaktade på sidan</h2> 
                                <TextEditor :model="publicPage" :prop="'contact_text'" :height="'600px'"></TextEditor>  
                            </div>
                            </div>
                        </div>
                        <div v-if="selectedTab == 'bokning'">
                            .
                        </div>
                        <div v-if="selectedTab == 'customTab'">
                            <div class="padding-15">
                                <div class="AdminPublic-edit-section">
                                <h2>Skriv en text</h2> 
                                <TextEditor :model="selectedCustomTab" :prop="'text'" :height="'600px'"></TextEditor>  
                            </div>
                            </div>
                        </div>
                    </div>
                </div>   
                <div class="g-60">                    
                    <div class="info-box overflow-auto">
                        <div class="g-80">
                            Föreningens publika sida är påslagen och nås på <a :href="'https://www.jamii.se/' + accountSettings.url_name" target="_blank"> https://www.jamii.se/{{ accountSettings.url_name }}</a>
                        </div>
                        <div class="g-20">
                            <button class="btn float-right" @click="saveEnablePublicPage">Inaktivera sidan.</button>
                        </div>
                    </div>
                    <div style="border: 1px solid black; background-color: white;">
                        <div class="AdminPublic-menu">                           
                            <div class="nav">                    
                                <div @click="selectedTab = 'hem'" :style="{ 'background-color': selectedTab == 'hem' && !showBookings ? publicPage.background_color : '' }"><h3> Hem </h3></div>
                                <div @click="selectedTab = 'foreningen'" :style="{ 'background-color': selectedTab == 'foreningen'  && !showBookings  ? publicPage.background_color : '' }"><h3>{{this.$store.state.settings.Community.singular}}  </h3> </div>
                                <div v-for="tab in customTabs" :style="{ 'background-color': selectedTab == 'customTab' && selectedCustomTab.name == tab.name  && !showBookings  ? publicPage.background_color : '' }">
                                   <h3 @click="selectCustomTab(tab)"> {{ tab.name }}</h3>
                                </div>
                                <div v-if="publicPage.include_image_gallery" @click="selectedTab = 'bildgalleriet'" :style="{ 'background-color': selectedTab == 'bildgalleriet'  && !showBookings ? publicPage.background_color : '' }"><h3>{{this.$store.state.settings.ImageGallery.singular}}  </h3> </div>
                                <div v-if="availableBookings.length == 1" @click="showBooking(availableBookings[0])" :style="{ 'background-color': selectedTab == 'bokning' ? publicPage.background_color : '' }">
                                    <h3>{{ availableBookings[0].name }}</h3>                               
                                </div>                 
                                <div v-if="availableBookings.length > 1" :style="{ 'background-color': selectedTab == 'bokning' || showBookings ? publicPage.background_color : '' }">              
                                    <h3 @click="showBookings = !showBookings">Boka</h3>  
                                    <span v-if="showBookings">                            
                                        <h3 v-for="booking in availableBookings" @click="showBooking(booking)">{{ booking.name }}</h3>  
                                    </span>                              
                                </div>                 
                                <div v-if="publicPage.include_contact" @click="selectedTab = 'kontakt'" :style="{ 'background-color': selectedTab == 'kontakt'  && !showBookings  ? publicPage.background_color : '' }"><h3>Kontakt</h3> </div>                 
                            </div>
                        </div>

                    <div v-show="selectedTab == 'hem'">
                        <div class="top-public" style="height: 650px;" :style="getBackgroundImage('home')">
                            <div class="AdminPublic-home-header blur-background" :style="{ 'color': publicPage.header_color }" style="position: absolute; top: 200px;">Välkommen till <br> {{ customer.name }}</div>                           
                            <SelectImage :publicPageImages="publicPageImages" :location="'home'"></SelectImage>    
                        </div>
                        <div style="margin:20px 90px; overflow: auto;">                       
                            <div v-html="this.$adaptHtml.adaptHtml(publicPageHome.welcome_text, publicPage.header_color)"></div>
                        </div>
                        <div style="padding:20px 90px;" :style="{ 'background-color': publicPage.background_color }">
                            <h1 style="margin-top: 40px;" :style="{ 'color': publicPage.header_color }">Aktuellt</h1>  
                            <div v-for="notification in notifications" class="AdminPublic-notification-item">   
                                <div v-html="notification.message"></div>
                                <div v-for="image in notification.images">
                                    <img :src="url + 'uploads/'+ image.customer_id+'/images/'+ image.name"  style="width: 100%" />  
                                </div>              
                                <div style="margin-top:10px;">
                                    <i>/{{ notification.created_by_user.first_name }}
                                        {{ notification.created_by_user.last_name }},  
                                        <span v-date="{ date: notification.created_date, type: 'dateInLetters' }"></span>  
                                    </i>
                                </div>    
                            </div>                                            
                        </div>
                    </div>
                    <div v-show="selectedTab == 'foreningen'">
                        <div class="top-public" :style="getBackgroundImage('community')">                           
                            <SelectImage :publicPageImages="publicPageImages" :location="'community'"></SelectImage>    
                        </div>
                        <div style="margin: 20px 90px;">
                        <div v-html="this.$adaptHtml.adaptHtml(publicPageCommunity.community_text, publicPage.header_color)"></div>
                        <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_board_members" v-fade-in>
                            <h2 :style="{ 'color': publicPage.header_color }">{{this.$store.state.settings.BoardMember.singular}}</h2>
                            <table>
                                <tr v-for="member in boardMembers" class="table-style">                       
                                <td> {{member.board_member_type}}</td>
                                <td> {{member.user_name}} ({{ member.election_period }})</td>
                                </tr>
                            </table>
                        </div>
                        
                        <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_work_groups" v-fade-in>
                            <h2 :style="{ 'color': publicPage.header_color }">{{this.$store.state.settings.WorkGroup.plural}}</h2>
                            <table>
                                <tr v-for="group in workGroups" class="table-style">                       
                                <td> {{ group.name }}</td>
                                <td> {{ group.members.map(x => x.user.full_name).join(", ") }}</td>
                                </tr>
                            </table>
                        </div>
                        
                        <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_statutes" v-fade-in>
                            <h2 :style="{ 'color': publicPage.header_color }">{{this.$store.state.settings.Statute.plural}}</h2>                          
                            <ul style="padding-left: 20px;">
                                <li v-for="statute in statutes" class="table-style">                       
                                    <span v-html="statute.statute"></span>                          
                                </li>
                            </ul>
                        </div>
                        
                        <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_rules_of_procedure" v-fade-in>
                            <h2 :style="{ 'color': publicPage.header_color }">{{this.$store.state.settings.RuleOfProcedure.plural}}</h2>
                            <ul style="padding-left: 20px;">
                                <li v-for="rule_of_procedure in rulesOfProcedure" class="table-style">                       
                                    <span v-html="rule_of_procedure.rule_of_procedure"></span>                          
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                    <div v-show="selectedTab == 'bildgalleriet' && customer != null" :style="{ 'background-color': publicPage.background_color }">
                        <div class="top-public" :style="getBackgroundImage('image_gallery')">
                            <SelectImage :publicPageImages="publicPageImages" :location="'image_gallery'" v-fade-in></SelectImage>    
                        </div>
                        <h2 :style="{ 'color': publicPage.header_color }" class="padding-15">Bildgalleriet</h2>
                        <div class="overflow-auto" style="padding: 10px;">
                            <div v-for="image in images.filter(x => x.include_in_image_gallery)" class="AdminImageVault-img-area">              
                                <img :src="url + 'uploads/'+ customer.id+'/images/thumbnails/'+ image.name" @click="highlightedImage = image" />               
                            </div>
                        </div>
                    </div>
                    <div v-show="selectedTab == 'kontakt' && customer != null">
                        <div class="top-public" :style="getBackgroundImage('contact')">
                            <SelectImage :publicPageImages="publicPageImages" :location="'contact'"></SelectImage>
                           
                        </div>
                        <h2 :style="{ 'color': publicPage.header_color }" class="padding-15">Kontakt</h2>     
                        <div class="overflow-auto padding-15">                       
                            <div v-html="this.$adaptHtml.adaptHtml(publicPage.contact_text, publicPage.header_color)"></div>            
                        </div>
                    </div>
                    <div v-if="selectedTab == 'customTab' && selectedCustomTab != null">
                        <div class="top-public" :style="{ 'background-image': selectedCustomTab.image_name != null ? 'url(' + url + 'uploads/'+ customer.id+'/images/'+ selectedCustomTab.image_name + ')' : ''}">
                            <button class="btn" style="position: absolute; right: 10px; bottom: 10px;" @click="editBg = { model: 'selectedCustomTab', prop: 'image_name', id: 'image_id' }">Välj bakgrundsbild från bildbanken</button>
                        </div>
                        <h2 :style="{ 'color': publicPage.header_color }" class="padding-15">{{selectedCustomTab.name}}</h2>     
                        <div class="overflow-auto padding-15">                       
                            <div v-html="this.$adaptHtml.adaptHtml(selectedCustomTab.text, publicPage.header_color)"></div>            
                        </div>
                    </div>
                    <div v-if="selectedTab == 'bokning' && bookingItem != null">
                        <div class="top-public" :style="{ 'background-image': bookingItem.image_name != null ? 'url(' + url + 'uploads/'+ customer.id+'/images/'+ bookingItem.image_name + ')' : '' }">
                            
                        </div>
                        <div class="overflow-auto padding-15" style="width:80%; margin: auto;">                       
                            <div v-html="this.$adaptHtml.adaptHtml(bookingItem.information, publicPage.header_color)"></div>            
                        </div>
                        <div class="overflow-auto padding-15 info" style="width:80%; margin: auto;">                       
                            <div v-html="this.$adaptHtml.adaptHtml(bookingItem.rules, publicPage.header_color)"></div>            
                        </div>
                        <div class="fake-calendar">
                            <div class="preview">
                                <p class="info-box">
                                    Detta är bara en förhandsgranskning. Gå till den publika sidan för att boka/testa bokningen.
                                </p>
                            </div>
                            <div>
                                <b style="border-top: 1px solid #ddd;">Mån</b>
                                <b style="border-top: 1px solid #ddd;">Tis</b>
                                <b style="border-top: 1px solid #ddd;">Ons</b>
                                <b style="border-top: 1px solid #ddd;">Tors</b>
                                <b style="border-top: 1px solid #ddd;">Fre</b>
                                <b style="border-top: 1px solid #ddd;">Lör</b>
                                <b style="border-top: 1px solid #ddd;">Sön</b>
                            </div>
                            <div>
                               <i>1</i>
                               <i>2</i>
                               <i>3</i>
                               <i>4</i>
                               <i>5</i>
                               <i>6</i>
                               <i>7</i>
                            </div>
                            <div>
                                <i>8</i>
                               <i>9</i>
                               <i>10</i>
                               <i>11</i>
                               <i>12</i>
                               <i>13</i>
                               <i>14</i>
                            </div>
                            <div>
                                <i>15</i>
                               <i>16</i>
                               <i>17</i>
                               <i>18</i>
                               <i>19</i>
                               <i>20</i>
                               <i>21</i>
                            </div>
                            <div>
                                <i>22</i>
                               <i>23</i>
                               <i>24</i>
                               <i>25</i>
                               <i>26</i>
                               <i>27</i>
                               <i>28</i>
                            </div>
                            <div>
                                <i>29</i>
                               <i>30</i>
                               <i>31</i>                              
                            </div>
                        </div>
                    </div>
                    <div class="public-footer" :style="{ 'background-color': publicPage.background_color }">
        <table>
            <tr>
                <td style="text-align: center;">
                    &copy; {{ new Date().getFullYear() }} {{ customer.name }} 
                </td>
                <td></td>
                <td>
            
                    <b>Meny</b> 
                    <ul>
                        <li>
                            Hem     
                        </li>
                        <li>
                            {{this.$store.state.settings.Community.singular}}     
                        </li>
                        <li v-if="publicPage.include_image_gallery">
                            {{this.$store.state.settings.ImageGallery.singular}}
                        </li>                     
                        <li v-if="publicPage.include_contact">
                            Kontakta Oss      
                        </li>                  
                    </ul>     
                </td>
                <td>
                    <b>Boka</b>
                    <ul>
                        <li v-for="booking in availableBookings">
                               {{ booking.name }}                                                                           
                        </li>
                    </ul>
                </td>
            </tr>
        </table>  
                        <div class="public-footer-samfallify" :style="{ 'color': publicPage.background_color }">{{ customer.name }} är en del av jamiis&copy; härliga community.</div>                  
                    </div>
                </div>
                </div>
                <button class="btn AdminPublic-publish-btn" v-show="hasChanges" @click="saveAndPublishPublicPage">Spara och publicera</button>
            </div>
            <div v-else>
                <div class="info-box overflow-auto">Som kund hos jamii har ni möjligheten att ha en publik sida som allmänheten kan komma åt. <br><br>
                    Adressen till föreningens publika sida kommer att vara:
                    <h3>https://www.samfallify.se/{{ accountSettings.url_name }}</h3>
                    Den publika sidan kan anpassa sig efter vad ni vill ska visas utåt och går att inaktivera när som helst. 
                    <p class="margin-top-15">
                        <button class="btn" @click="saveEnablePublicPage">Ja, vill vill gärna aktivera den publik sidan.</button>
                    </p>
                </div>
            </div>
        </main>
    </div>
    <PopUp v-if="editBg != null && customer != null">
        <h2>Välj en {{ editBg.text != undefined ? editBg.text : 'bakgrundsbild' }}</h2>     
        <div class="overflow-auto">
            <div v-for="image in images" class="AdminImageVault-img-area">
                <img :src="url + 'uploads/'+ customer.id+'/images/thumbnails/'+ image.name" @click="selectBackgroundImage(image)" />                
            </div>
        </div>
        <button class="btn float-right" @click="editBg = null">Avbryt</button>
    </PopUp>
</template>
<style scoped>
h1{
    font-size: 20px;
}
td{
    padding: 5px 10px 2px 0px;
}

.AdminPublic-community-table
{
    margin-bottom: 30px;
}
.AdminPublic-menu
{
    width:100%; 
    height: 120px; 
    color: white;
    position: relative;
    z-index: 1;
    font-size: 14px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.AdminPublic-custom-tabs span
{    
    color: white;
    font-size: 14px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.AdminPublic-custom-tabs
{
    width: fit-content;
    padding:10px 20px;
    margin-bottom: 4px;

}

.AdminPublic-custom-tabs i
{
    cursor: pointer;
}

.AdminPublic-publish-btn
{
    padding: 15px 0px;
    background-color: #58d64d !important;
    color: white;
    position:fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    font-size: 16px;
}
.AdminPublic-home-header
{
    position: absolute;
    top: 300px;
    width:100%;
    text-align: center;
    font-size: 20pt;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-family: "Fredoka", sans-serif;
        font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: rgba(255,255,255,0.4);
    padding: 15px 0px;
}

.AdminPublic-divider
    {        
        width: 100%;
        height: 1px;
        margin-top: 15px;
        margin-bottom: 10px;
        filter: brightness(90%);
    }
.logo
{
    color: white;
    float:left;
    padding: 10px;
    font-family: 'Titan One', cursive;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.logo i{
    font-size: 30pt;
    color: white;
    float:left;
    display: block;
    padding:10px 10px 0px 0px;
}

.logo img{
    max-height: 100px;
}

.logo p{
    position: absolute; 
    top: 115px; 
    left: 10px;
    margin: 0px;

}

.top-public
{
    background-image: url('@/assets/imgs/Bryggan.jpg');
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin-top: -120px;
    position: relative;
}

.nav{
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 9pt;
}
.nav div{
    cursor: pointer;
    text-align: center;
    vertical-align: top;
    padding: 0px 15px;
}

.table-style div
{
    display: inline-block;
    padding: 2px 10px 2px 0px;
    width:50%;
}

.public-footer
{
    background-color: black;
    width:100%;
    filter: brightness(90%);
    padding: 70px 0px;
}

.public-footer table
{
    width: 70%;
    margin: auto;
}

.public-footer td
{
    vertical-align: top;
}

.public-footer-samfallify 
{
    position: absolute;
    bottom: 7px;
    right: 7px;
    font-size: 12px;  
    filter: brightness(70%);
}
.public-footer ul
{
    list-style: none;
    padding: 0px;
}

.public-footer li
{
   margin-bottom:10px;
   cursor: pointer;
}

.public-footer i
{
    filter: brightness(110%);
    font-size: 70pt;
    padding: 15px;
}

.fake-calendar
{
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.fake-calendar .preview
{
  position: absolute;
  width: calc(100% - 100px);
  background-color: rgba(255,255,255,0.8);
  height: 400px;
  border: 0px;
  text-align: center;
  padding: 50px;
  padding-top: 180px;
}

.fake-calendar div
{
    border-left: 1px solid #ddd;
}

.fake-calendar b{
    display: inline-block;
    width: 14%;
    text-align: center;
    padding:5px 0px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.fake-calendar i{
    display: inline-block;
    width: calc(14% - 7px);
    height: 100px;
    padding: 5px 7px 0px 0px;
    text-align: right;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

/* images */
.AdminImageVault-img-area
{
    float: left;   
    color: white;
    font-size: 11px;
    position: relative;
    padding: 10px;

}

    .AdminImageVault-img-area img
    {
        cursor: pointer;
        padding: 5px;
        margin: auto;
        height: 200px;
    }

    .AdminPublic-notification-item
    {
        padding:15px;
        margin: 5px 30px;
        border-radius: 4px;
        margin-bottom: 10px;
        display: inline-block;
        width: calc(50% - 90px);
        vertical-align: top;
        border-top: 1px solid #ddd;
    }

    .AdminPublic-notification-item i{
        font-size: 14px;
    }
</style>