<script>
import AdminLink from '../components/AdminLink.vue'
import BookingItem from '../components/BookingItem.vue'
import config from "@/helpers/config"
export default {
    components:{ AdminLink, BookingItem },
    created() {    
        this.$cache.createCache('availableBookings','available-bookings/')
    },
    computed: {
        availableBookings() { return this.$store.state.availableBookings != null ? this.$store.state.availableBookings : []; },
        url() { return config.urlToServer },
        customer() { return this.$store.state.customer; }
    }
}
</script>
<template>
    <main class="opacity-white-bg" v-fade-in>
        <section>
            <h2>Välj Bokning</h2>
            <div class="BookingList-wrapper">
                <div v-for="booking in availableBookings" class="BookingList-booking-item"> 
                    <BookingItem :booking="booking" :url="url" :customer="customer"></BookingItem>                  
                </div>
            </div>
        
            <div class="overflow-auto">
                <AdminLink :buttonText="'Lägg till/ta bort bokningsbart'" :sectionToEdit="'adminBookings'"></AdminLink>
            </div>                    
        </section>
    </main>
</template>
<style scoped>
    .BookingList-wrapper
    {
        display:flex;
        flex-wrap: wrap;

    }
    .BookingList-booking-item
    {
        padding:10px; 
        margin: 20px;
    }
    
    
    
    @media only screen and (max-width: 1000px) {
        .BookingList-booking-item
        {
            padding:10px; 
            margin: 30px;
            width: calc(100% - 80px);
        }       
    }
</style>