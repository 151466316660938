<script>
export default {
    props: ['positionLeft'],
    data(){
        return {
            modules: this.$store.state.modules,
            selectedModule: 'news',
            shortMonths: ['','jan', 'feb', 'mars', 'apr', 'maj', 'jun', 'jul', 'aug','sep','okt', 'nov', 'dec'],
            noOfShownPastEvents: 4
        }
    },
    created()
    {       
        this.$cache.createCache('incomingEvents', 'incoming-events/')
    },
    computed:
    {
        notifications() {  return this.$store.state.notifications; },
        comingUpEvents() { return this.$store.state.incomingEvents == null ? []
             : this.$store.state.incomingEvents.filter(x => !x.expired).sort(function (a, b) {
                 return new Date(a.formatted_start_date) > new Date(b.formatted_start_date) ? 1 : -1;
             }) 
        },
        pastEvents() { 
            return this.$store.state.incomingEvents == null ? [] :
             this.$store.state.incomingEvents.filter(x => x.expired).sort(function (a, b) {
                 return new Date(a.formatted_start_date) < new Date(b.formatted_start_date) ? 1 : -1;
             }) 
        },
    }
}
</script>
<template>
    <h2>På Gång</h2>
    <div v-for="event in comingUpEvents" class="event" :key="event.id">
        <div class="date-circle" :style="{ 'background-color': event.circleColor }">         
            <b>{{ event.date }}</b>
            <br>
            <span>{{ shortMonths[event.month] }}</span>
        </div>
       
            <b><span v-if="event.type=='booking'">{{this.$store.state.settings.BookingItem.singular}} av</span> {{ event.title }}</b><br>
            <span>{{ event.timespan }}</span>
            <br>
            <router-link  v-if="event.type=='booking'" to="/booking">Gå till {{this.$store.state.settings.BookingItem.plural}}</router-link>
            <router-link v-else to="/invitation">Gå till din {{this.$store.state.settings.Invitation.singular}}</router-link>
    </div>
    <div v-if="comingUpEvents.length == 0">
        Du har dessvärre inget på gång :(
    </div>
    <div v-if="pastEvents.length > 0">
    <h2 class="margin-top-25">Har hänt</h2>
    <div class="overflow-auto">
        <div v-for="event in pastEvents.slice(0, noOfShownPastEvents)" class="event" :key="event.id">
            <div class="date-circle">         
                <b>{{ event.date }}</b>
                <br>
                <span>{{ shortMonths[event.month[0] == 0 ? event.month[1] : event.month] }}</span>
            </div>
        
            <b><span v-if="event.type=='booking'">{{this.$store.state.settings.BookingItem.singular}} av</span> {{ event.title }}</b><br>
            <span>{{ event.timespan }}</span>
            <br>
            <router-link  v-if="event.type=='booking'" to="/booking">Gå till {{this.$store.state.settings.BookingItem.plural}}</router-link>
            <router-link v-else to="/invitation">Gå till din {{this.$store.state.settings.Invitation.singular}}</router-link>

        </div>
    </div>
    <div class="text-right" style="margin: 15px -4px 15px 4px;" v-show="noOfShownPastEvents < pastEvents.length">
        <span class="btn"  @click="noOfShownPastEvents += 4">Visa fler</span></div>      
    </div>
</template>
<style scoped>
    .event
    {
        overflow:auto;
        font-size: 13px;
        margin-bottom:10px;
        line-height: 1.4;

    }
    .date-circle
    {
        float:left;
        background-color:#cacaca;
        border-radius: 30px;
        width: 60px;
        height: 43px;
        padding-top: 17px;
        text-align: center;
        line-height: 13px;
        color: white;
        margin: 0px 10px 0px 0px;
    }
    
    .date-circle span
    {
        font-size: 13px;
    }
    
    .date-circle b
    {
        font-size: 19px;
    }


</style>