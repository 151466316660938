export default {
    getLinks(settings) { 
      return [{ enabled: true,  viewName: 'home', url: '/', icon: 'fa-regular fa-paper-plane', text: settings.News.singular, name: ['home']  },
              { enabled: true, viewName: 'community', url: '/community/', icon: 'fa-solid fa-users', text: settings.Community.singular, name:['community']  },
              { enabled: settings.Forum.enabled,  viewName: 'forum', url: '/forum', icon: 'fa-regular fa-comments', text: settings.Forum.singular, name:['forum', 'forumThread','forumCategory'] },
              { enabled: settings.BookingItem.enabled, viewName: 'bookings', url: '/booking', icon: 'fa-solid fa-calendar-days', text:settings.BookingItem.plural, name:['bookings','bookingList','bookingDaysAdd','bookingHoursAdd']  },              
              { enabled: settings.Invitation.enabled, viewName: 'invitations', url: '/invitation', icon: 'fa-solid fa-handshake-simple', text:  settings.Invitation.plural, name:['invitations', 'invitation']  },
              { enabled: true, viewName: 'imageGallery', url: '/image-gallery', icon: 'fa-solid fa-images', text:  settings.ImageGallery.singular, name:['imageGallery']  },
          ]
    },
    hideMenuPages: [
      'admin', 
      'adminNews',
      'adminInvitations', 
      'adminForums', 
      'adminBoardMembers', 
      'adminWorkGroups', 
      'adminMembers', 
      'adminBookings', 
      'adminDocuments',
      'adminInvitationsAdd',
      'adminInvitationsView',
      'adminStatutes',
      'adminRulesOfProcedure',
      'adminAccount',
      'adminPublic',
      'adminImageVault',
      'adminBookingsEdit',
      'adminBookingsAdd',
      'adminInvitationsEdit',
      'adminDocumentCategories',
      'adminSettings'
    ]
  } 