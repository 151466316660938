<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
import AdminBookingsReoccurring from '@/views/Admin/AdminBookingsReoccurring.vue'

export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction, AdminBookingsReoccurring },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('accountSettings', 'account-settings/') 
        this.$cache.createCache('availableBookings', 'available-bookings/');
    },
    data() {
        return {             
            sorting: [
                { name: 'name', displayName: 'Namm' },           
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ]  ,         
            selectedBookingItem: null           
        }
    },   
    methods:
    {
        edit(item)
        {
            this.$router.push('/admin/bookings/' + item.id);   
        },
        add()
        {
            this.$router.push('/admin/bookings/add');          
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('available-bookings/' + id + '/delete')
            .then(function(data)
            {                 
                self.getBookings();
                message.setSuccessMessage();
            })
            .fail(function(error) {                  
            })  
        },
        getBookings()
        {
            this.reoccuringBooking = null;
            this.$cache.updateCache('availableBookings', 'available-bookings/');          
        },
        openReoccuringBookning(item)
        {
            this.selectedBookingItem = item;
        },
        closeReoccuringBookning()
        {
            this.selectedBookingItem = null;
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },
        availableBookings() { 
            if(this.selectedBookingItem != null)
            {
               this.selectedBookingItem = this.$store.state.availableBookings.find(x => x.id == this.selectedBookingItem.id);
            }
            return this.$store.state.availableBookings != null ? this.$store.state.availableBookings : []; },
        columns() { 
            var cols = [                             
                { name: 'edit', displayName: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, align:'center', width: 60, hideFilter: true, hideIfEdit: true},                
                { name: 'name', displayName: 'Namn', width: 200, hideFilter: false},
                { name: 'duration', displayName: 'Bokningens maxlängd', width: 200, hideFilter: true},
                { name: 'availability', displayName: 'Tillgänglig för bokning', width: 260, hideFilter: true},
                { name: 'reoccuring', displayName: 'Återkommande bokningar', function: this.openReoccuringBookning, align:'center', icon: 'fa-solid fa-clock-rotate-left', width: 230, hideFilter: true},
                { name: 'information', displayName: 'Information', width: 800, hideFilter: false, isTextEditorFormat: true},
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},            
            ] 
            
            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                cols.splice(4,0,{ name: 'include_in_public_site', isBoolean: true, displayName: 'Visas även på den publika sidan', width: 250});
            }
            return cols;
        }       
    }
}
</script>
<template>
    <div v-fade-in  v-if="accountSettings != null">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till ett bokningsobjekt</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Bokningar'"></ToAdmin>
            <h2>Hantera bokningar</h2>               
            <List :items="availableBookings" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :nameOfItems="'tillgängliga bokningar'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
        </main>
    </div>
    <AdminBookingsReoccurring :selectedBookingItem="selectedBookingItem" :closeReoccuringBookning="closeReoccuringBookning"></AdminBookingsReoccurring>   
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>