
const tooltip = {
  mounted(el, binding) {
      //do only if title is set
      if (binding.value != undefined && binding.value != null && binding.value != '') {
          var title = binding.value;

          $(el).on("mouseover",function (e) {
              var elmPosition = e.currentTarget.getBoundingClientRect();
              var html = "<div class='v-tooltip' style='top:" + (elmPosition.top + window.scrollY + elmPosition.height + 10) + "px;'>";
              html += "<i class='fa-solid fa-caret-up v-tooltip-triangle'></i>" + title + "</div>";
              $('body').append(html);
              var tooltipWidth = $(".v-tooltip").outerWidth();
              $(".v-tooltip").css({ left: (elmPosition.left + window.scrollX + elmPosition.width / 2) - tooltipWidth / 2 - 6 + "px" });
              $(".v-tooltip").addClass('v-tooltip-show');
          });

          $(el).on("mouseout",function (e) {
              $('body').find('.v-tooltip').remove();
          });
      }
  }
}
const hover = {
  mounted(el) {
        $(el).on("mouseover",function (e) {
            $(e.currentTarget).addClass('wow-color-hover');        
        });

        $(el).on("mouseout",function (e) {
          $(e.currentTarget).removeClass('wow-color-hover');
        });   
  }
}

const fadeIn = {
  mounted(el, binding) {
    $(el).css({ opacity: 0});
    var timer = binding.value != undefined ? binding.value : 300;
    setTimeout(() => {    
      $(el).addClass('section-visible');
  }, timer);
  }
}

const date = {
  mounted(el, binding) {   
    var splittedDate = binding.value.date.split('T');
    if(binding.value.displayTodayText)
    {
      var date = splittedDate[0];
        var jsDate = new Date(date);
        var today = new Date();
        if(jsDate.getMonth() == today.getMonth() && jsDate.getDate() == today.getDate()) {
           $(el).text('Idag');
           return;
        }
    }

    var months = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december']
    var shortMonths = ['jan', 'feb', 'mars', 'apr', 'maj', 'jun', 'jul', 'aug','sep','okt', 'nov', 'dec']
    var days = [ 'söndagen', 'måndagen', 'tisdagen', 'onsdagen', 'torsdagen', 'fredagen', 'lördagen']

    switch(binding.value.type)
    {
      case 'date':
        $(el).text(splittedDate[0]);
        break;
      case 'dateAndTime':
        $(el).text(splittedDate[0] + ' ' + splittedDate[1].substring(0,5));
        break;
      case 'time':
          $(el).text(splittedDate[1].substring(0,5));
          break;
      case 'dateInLetters':
        var date = splittedDate[0];
        var dayInWeek = new Date(date).getDay();     
        var monthNumber = parseInt(date.substring(5,7));
        $(el).text(days[dayInWeek] + ' den '+ date.substring(8,10)+' ' + months[monthNumber-1] + ' ' + date.substring(0,4));
        break;
      //e.g. 27 juni
      case 'monthDateInLetters':
        var date = splittedDate[0];
        var jsDate = new Date(date);
        var today = new Date();
        if(jsDate.getMonth() == today.getMonth() && jsDate.getDate() == today.getDate()) { $(el).text('Idag'); break; }
        
        var monthNumber = parseInt(date.substring(5,7));
        $(el).text(parseInt(date.substring(8,10))+' ' + months[monthNumber-1]);
        
        break;
      case 'month':
        var date = splittedDate[0];
        var monthNumber = parseInt(date.substring(5,7));
        $(el).text(months[monthNumber-1]);
        break;      
      case 'shortMonth':
        var date = splittedDate[0];
        var monthNumber = parseInt(date.substring(5,7));
        console.log(date.substring(5,7));
        $(el).text(shortMonths[monthNumber-1]);
        break;
      
      case 'dateNumber':
        var date = splittedDate[0];
        $(el).text(parseInt(date.substring(8,10)));
        break;

    }    
  }
}
 
const htmlToText = {
  mounted(el, binding) {
    var html = binding.value.replace( /(<([^>]+)>)/ig, ' ');
    $(el).text(html);
  }
}

export default {
  tooltip,
  date, 
  hover,
  fadeIn,
  htmlToText
}