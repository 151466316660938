<script>
import Select from './Select.vue'
import MultiSelect from './MultiSelect.vue'
import SelectImages from '@/components/SelectImages.vue'
import SelectImage from '@/components/SelectImage.vue'
import TextEditor from '@/components/TextEditor.vue'
import Password from './Password.vue'
import Date from './Date.vue'
import Switch from './Switch.vue'
import DateAndTime from './DateAndTime.vue'
import message from '@/helpers/message'
export default {
    components: { Select, Date, MultiSelect, DateAndTime, Password, Switch, SelectImages, SelectImage, TextEditor },
    props: ['form', 'item', 'url', 'getItemFunction', 'saveText', 'inEditMode', 'hideCancel', 'successMessage', 'keepInEditModeAfterSave', 'page', 'isAddingMember'],
    data(){
        return {
            isEditing: this.inEditMode != undefined ? this.inEditMode : false,
            textOnSaveBtn: this.saveText != undefined ? this.saveText : 'Spara',
            isSaving: false        
        }
    },
    created()
    { 
        this.setHiddenSections(this.item);
        this.setModified(false);
    },
    methods:
    {
        saveAndSendEmail() {
            this.item.has_recieved_email = true;
            this.save();
        },      
        save()
        {
            var self = this;
            message.removeMessage();
            this.setModified(true);
            //validation
            var validationErrors = [];
            this.form.forEach(function(row) {
                row.sections.forEach(function(section) {
                    if(!section.hidden)
                    {
                        section.inputs.forEach(function(input) {
                            if(input.required != undefined && input.required && (self.item[input.prop] === '' || self.item[input.prop] === null))
                            {                             
                                validationErrors.push("Du måste ange '" + input.title + "'");
                            }
                        })
                    }
                });
            });
            
            if(validationErrors.length > 0)
            {
                this.item.has_recieved_email = false;
                message.setValidationErrorMessage(validationErrors);
                return;
            }
            
            //save
            this.isSaving = true;
            var self = this;
            this.$communicator.post(this.url, this.item)
                .then(function(data)
                {
                    self.getItemFunction(data);
                    self.isEditing = self.keepInEditModeAfterSave ? true : false;
                    message.setSuccessMessage(self.successMessage);
                })
                .fail(function(error) {   
                    if(error.responseJSON.detail == 'Email already registered')
                    {
                        message.setValidationErrorMessage(['Emailen du har angett används redan. Var god väl en annan email-adress']);
                    }
                    else if(error.responseJSON.detail == 'Passwords not matching')
                    {
                        message.setValidationErrorMessage(['De nya lösenorden du matade in matchar inte. Försök igen.']);
                    }              
                    else if(error.responseJSON.detail == 'Old password incorrect')
                    {
                        message.setValidationErrorMessage(['Du har skrivit in ett felaktigt nuvarande lösenord']);
                    }             
                    self.isSaving = false;
                    self.item.has_recieved_email = false;
                })
                .done(function(data) {     
                    self.isSaving = false;
                })   
        },
        cancel()
        {
            this.getItemFunction();
            message.removeMessage();
            this.isEditing = false;
        },
        edit()
        {
            this.setModified(false);
            message.removeMessage();
            this.isEditing = true;
        },      
        setModified(isModified)
        {
            this.form.forEach(form => {
                form.sections.forEach(section => {
                    section.inputs.forEach(input => {
                        input.modified = isModified;
                    })
                });
            });
        },
        setHiddenSections(item)
        {
            this.form.forEach(form => {
                form.sections.forEach(section => {
                    if(section.showIf !=undefined)
                    {
                       switch(section.showIf.method)
                       {
                            case 'equals':
                                if((item[section.showIf.prop] == undefined) ||  item[section.showIf.prop] != section.showIf.value)
                                {
                                    section.hidden = true;
                                }
                                else
                                {
                                    section.hidden = false;
                                }
                                break;
                       }
                        
                    } else
                    {
                        section.hidden = false;
                    }
                });
                
            }); 
        }    
    },
    watch:
    {
        item: {
            handler(item){
                this.setHiddenSections(item);
            },
            deep: true
        }
    }
}
</script>
<template>
    <div class="g-100 overflow-auto" v-if="item != undefined">
        <div v-for="row in form" class="overflow-auto">
            <div v-for="(section, rowIndex) in row.sections.filter(x => !x.disabled)" :class="section.classes" class="float-left">
                <div v-show="!section.hidden">
                    <fieldset :style="{'background-color': row.backgroundColor, 'border-color': row.borderColor }">
                        <legend>
                            {{ section.sectionName }}
                        </legend>
                        <div v-for="(input, inputIndex) in section.inputs" :class="input.classes" class="float-left">
                            <div class="validation-error" v-if="isEditing && input.modified && input.required != undefined && input.required && (item[input.prop] == '' || item[input.prop] == null)">Du måste ange {{ input.title  }}</div>
                            <label>{{ input.title }}<span>{{ input.required != undefined && input.required ? '*' : ''  }}</span></label>
                            <div v-if="!isEditing && input.type == 'switch'" class="view-value" :style="{'border-color': row.borderColor }">{{ item[input.prop] ? 'Ja' : 'Nej' }}</div>
                            <div v-else-if="!isEditing && input.type == 'textEditor'" class="view-value-text-editor" v-html="item[input.prop]" :style="{'border-color': row.borderColor }"></div>
                            <div v-else-if="!isEditing" class="view-value" v-html="item[input.prop]" :style="{'border-color': row.borderColor }"></div>
                            <div v-if="isEditing">
                                <div v-if="input.type == 'text'">
                                    <input type="text" v-model="item[input.prop]" @keyup="input.modified = true" :class="{'validation-error-border':input.modified && input.required != undefined && input.required && item[input.prop].trim() == ''}">
                                </div>
                                <div v-else-if="input.type == 'infoText'" class="Form-info-text">
                                    <div v-html="input.infoText"></div>
                                </div>
                                <div v-else-if="input.type == 'textArea'">
                                    <textarea v-model="item[input.prop]" @keyup="input.modified = true" :class="{'validation-error-border': input.modified && input.required != undefined && input.required && item[input.prop] == ''}"></textarea>
                                </div>
                                <div v-else-if="input.type == 'select'">                               
                                    <Select :prop="input.prop" v-model:selectedItemSysID="item[input.prop]" :currentItemDisplayName="item[input.prop]" :selectItems="input.selectItems" :hasValidationErrors="input.modified && input.required != undefined && input.required && item[input.prop] == null" :index="rowIndex + '_' +  inputIndex"></Select>                            
                                </div>
                                <div v-else-if="input.type == 'password'">                               
                                    <Password v-model:value="item[input.prop]" :prop="input.prop" :hasValidationErrors="input.modified && input.required != undefined && input.required && item[input.prop] == null"></Password>
                                </div>
                                <div v-else-if="input.type == 'multiSelect'">                               
                                    <MultiSelect v-model:selectedItemSysIDs="item[input.prop]" :selectItems="input.selectItems"></MultiSelect>                            
                                </div>
                                <div v-else-if="input.type == 'date'">                               
                                    <Date v-model:value="item[input.prop]" :prop="input.prop" :startWithYears="input.startWithYears" :hasValidationErrors="input.modified && input.required != undefined && input.required && item[input.prop] == null"></Date>
                                </div>
                                <div v-else-if="input.type == 'dateAndTime'">                               
                                    <DateAndTime v-model:value="item[input.prop]" :prop="input.prop" :startWithDateNow="input.startWithDateNow" :startWithYears="input.startWithYears" :hasValidationErrors="input.modified && input.required != undefined && input.required && item[input.prop] == null"></DateAndTime>
                                </div>
                                <div v-else-if="input.type == 'number'">
                                    <input type="text" v-model="item[input.prop]" @keyup="input.modified = true" :class="{'validation-error-border':input.modified && input.required != undefined && input.required && item[input.prop].trim() == ''}" class="Form-number">
                                </div>
                                <div v-else-if="input.type == 'switch'" class="margin-top-15">
                                    <Switch :model="item" :prop="input.prop"></Switch>
                                </div>
                                <div v-else-if="input.type == 'readOnly'" class="view-value">
                                    {{ item[input.prop] }}             
                                </div>
                                <div v-else-if="input.type == 'textEditor'" style="margin-right: 5px">
                                    <TextEditor :model="item" :prop="input.prop"></TextEditor> 
                                </div>
                                <div v-else-if="input.type =='images'">
                                    <SelectImages :image_ids="item[input.prop]"></SelectImages>                                 
                                </div>
                                <div v-else-if="input.type =='image'">
                                    <SelectImage :model="item"  :prop="input.prop"></SelectImage>                                 
                                </div>
                            </div>
                        </div>
                    </fieldset>  
                </div>  
            </div>  
        </div> 
    </div>
    <div class="required-info"><span>*</span> = obligatoriska uppgifter</div>
    <div class="btns">
        <button v-if="isAddingMember" class="btn btn-gray mobile-100w-btn float-right" v-show="isEditing" @click="saveAndSendEmail" :disabled="isSaving">Spara och skicka välkomstmail</button>
        <button class="btn mobile-100w-btn float-right" v-show="isEditing" @click="save" :disabled="isSaving">{{textOnSaveBtn}}</button>
        <button class="btn btn-gray mobile-100w-btn float-right" v-show="isEditing && !hideCancel" @click="cancel">Avbryt</button>
        <button class="btn mobile-100w-btn float-right" v-show="!isEditing" @click="edit">Ändra</button>
    </div>
</template>
<style scoped>
   .btns
   {
        width: 100%;
        text-align: right;
        overflow: auto;
   }

   fieldset
    {
        border: 1px solid #ddd;
        margin:5px;
        margin-bottom:15px;
        padding: 4px 15px 15px 15px; 
        text-align: left;
        background-color: white;
    }

    legend{
        font-weight: 600;
        font-size: 13px;
        text-align: left;
        text-transform: uppercase;
    }

    input 
    {       
        width: calc(100% - 29px);
        margin:2px 0px 10px 0px;
    }

    textarea{
        resize: none;
        width: calc(100% - 29px);
        min-height: 120px;
    }

    label{
        text-align: left;
        margin: 8px 0px 5px 0px;
        display: block;
    }

    label span, .required-info span{       
        font-family: Arial, Helvetica, sans-serif;
    }

    .validation-error
    {
        color: rgb(230, 70, 70);
        float:right;
        text-align: right;
        font-size: 13px;
        padding-right: 6px;
    }

    .required-info
    {
        text-align: left;
        margin-bottom: 15px;
    }

    .validation-error-border
    {
        border: 1px solid rgb(230, 70, 70);
        background-color: rgb(250, 202, 202) !important;
        color: rgb(230, 70, 70);
    }

    .view-value
    {
        border-top: 1px solid #ccc;
        margin-right: 10px;
        padding:8px 0px 16px 0px;
        font-weight: bold;
        font-size: 15px;
    }
    .view-value-text-editor
    {
        border-top: 1px solid #ccc;
        margin-right: 10px;
        padding:8px 0px 16px 0px;
        font-size: 15px;
    }

    .Form-number
    {
        text-align: right;
    }

    .Form-info-text
    {
        background-color: rgb(245, 212, 151);
        border: 1px solid orange;
        padding: 10px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    
</style>