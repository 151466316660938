<script>
import PopUp from '@/components/PopUp.vue'
import config from "@/helpers/config"
export default {
    components:{ PopUp },
    props: ['publicPageImages', 'location'],
    created() {    
        this.$cache.createCache('images', 'images/');         
    },
    data() {
        return {  
            editBg: false,
            publicPageImage: this.publicPageImages.find(x => x.location == this.location)
        }
    }, 
    methods:
    {
        selectBackgroundImage(image)
        {
            if(this.publicPageImage != null)
            {
                this.publicPageImage.image_id = image.id;
                this.publicPageImage.image_name = image.name;
            }
            else 
            {
                var img = {
                    id: 0,
                    image_id: image.id,
                    image_name: image.name,
                    location: this.location,
                    position: 'center'
                };
                
                this.publicPageImages.push(img);
                this.publicPageImage =  this.publicPageImages.find(x => x.location == this.location);
            }

            this.editBg = false;
        }
    },
    computed:
    {
        customer() { return this.$store.state.customer },    
        images() {  return this.$store.state.images != null ? this.$store.state.images : []  },    
        url() { return config.urlToServer },        
    },
    watch:
    {
        publicPageImages: {
            handler(newValue){  
                this.publicPageImage = this.publicPageImages.find(x => x.location == this.location)
            },
            deep: true
        },
    }
}
</script>
<template>
    <div style="position: absolute; right: 10px; bottom: 10px;">
        <button class="btn" @click="editBg = true" v-tooltip="'Välj bakgrundsbild från bildbanken'"><i class="fa-regular fa-image"></i></button>  
        <div v-if="publicPageImage != null" style="display: inline-block">                 
            <button class="btn"  :class="[publicPageImage.position != 'center' ? 'btn-gray' : '']" @click="publicPageImage.position = 'center'" v-tooltip="'Centrera bakgrundsbilden'"><i class="fa-solid fa-arrows-to-circle"></i></button> 
            <button class="btn" :class="[publicPageImage.position != 'top' ? 'btn-gray' : '']" @click="publicPageImage.position = 'top'" v-tooltip="'Positionera bakgrundsbild toppen'"><i class="fa-solid fa-arrows-up-to-line"></i></button>                   
            <button class="btn" :class="[publicPageImage.position != 'bottom' ? 'btn-gray' : '']" @click="publicPageImage.position = 'bottom'" v-tooltip="'Positionera bakgrundsbilden mot botten'"><i class="fa-solid fa-arrows-down-to-line"></i></button>                   
        </div>                  
    </div>
    <PopUp v-if="editBg && customer != null">
        <h2>Välj en {{ editBg.text != undefined ? editBg.text : 'bakgrundsbild' }}</h2>     
        <div class="overflow-auto">
            <div v-for="image in images" class="AdminImageVault-img-area">
                <img :src="url + 'uploads/'+ customer.id+'/images/thumbnails/'+ image.name" @click="selectBackgroundImage(image)" />                
            </div>
        </div>
        <button class="btn float-right" @click="editBg = null">Avbryt</button>
    </PopUp>
</template>

<style scoped>
/* images */
.AdminImageVault-img-area
{
    float: left;   
    color: white;
    font-size: 11px;
    position: relative;
    padding: 10px;

}

    .AdminImageVault-img-area img
    {
        cursor: pointer;
        padding: 5px;
        margin: auto;
        height: 200px;
    }
</style>