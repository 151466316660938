<script>
import BoardMembers from '@/components/BoardMembers.vue'
import Members from '@/components/Members.vue'
import WorkGroups from '@/components/WorkGroups.vue'
import Statutes from '@/components/Statutes.vue'
import Documents from '@/components/Documents.vue'
import RulesOfProcedure from '@/components/RulesOfProcedure.vue'
import Tabs from '../components/Tabs.vue'
export default {
    components: { BoardMembers, Members, WorkGroups, Tabs, Statutes, RulesOfProcedure, Documents },
    data() {
        return {
            selectedTab: null
        };
    },
    created() {
        this.$cache.createCache('workGroups', 'work-groups/') 
        this.$cache.createCache('statutes', 'statutes/') 
        this.$cache.createCache('rulesOfProcedure', 'rules-of-procedure/') 
    },
    computed:
    {
        workGroups() {  return this.$store.state.workGroups },
        statutes() {  return this.$store.state.statutes },
        rulesOfProcedure() {  return this.$store.state.rulesOfProcedure },
        tabs() {
            var tabs = this.$store.state.settings.BoardMember.enabled ?  [this.$store.state.settings.BoardMember.singular, this.$store.state.settings.User.plural] : [this.$store.state.settings.User.plural];
            this.selectedTab = this.$store.state.settings.BoardMember.enabled ? this.$store.state.settings.BoardMember.singular : this.$store.state.settings.User.plural
            
            if(this.$store.state.settings.WorkGroup.enabled && this.workGroups != null && this.workGroups.length > 0)
            {
                tabs.push(this.$store.state.settings.WorkGroup.plural);
            }

            tabs.push('Dokument');
            
            if(this.$store.state.settings.Statute.enabled && this.statutes != null && this.statutes.length > 0)
            {
                tabs.push(this.$store.state.settings.Statute.plural);
            }
            
            if(this.$store.state.settings.RuleOfProcedure.enabled && this.rulesOfProcedure != null && this.rulesOfProcedure.length > 0)
            {
                tabs.push(this.$store.state.settings.RuleOfProcedure.plural);
            }  
            return tabs
        }
    }
};
</script>
<template>
    <div v-fade-in v-if="workGroups != null">
        <main class="opacity-white-bg">
            <h2>{{this.$store.state.settings.Community.singular}}</h2>
            <Tabs :tabs="tabs" v-model:selectedTab="selectedTab">               
            </Tabs>
           
            <div v-show="selectedTab == this.$store.state.settings.BoardMember.singular">
                <BoardMembers></BoardMembers>
            </div>
            <div v-show="selectedTab == this.$store.state.settings.User.plural">
                <Members></Members>
            </div>
            <div v-show="selectedTab == this.$store.state.settings.WorkGroup.plural">
                <WorkGroups></WorkGroups>
            </div>
            <div v-show="selectedTab == this.$store.state.settings.Statute.plural">
                <Statutes></Statutes>
            </div>
            <div v-show="selectedTab == this.$store.state.settings.RuleOfProcedure.plural">
                <RulesOfProcedure></RulesOfProcedure>
            </div>
            <div v-show="selectedTab == 'Dokument'">
                <Documents></Documents>
            </div>
        </main>
    </div>

</template>
<style scoped>
    .Community-tabs-wrapper
    {
        width:100%;
        border-bottom: 1px solid black;
        margin-bottom: 15px;

    }

    .Community-tabs-wrapper h2 {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        margin: 0px;
    }
</style>
