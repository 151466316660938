<script>
export default {
    props: ['members'],
    data() {
        return {  
            
        }
    },  
    methods: {
       
    }
}
</script>
<template>
    <section v-fade-in>
        <h2>Deltagare</h2>
        <div id="CanvasCommunicate-member-area">
            <span v-for="(member, index) in members" :key="index">
                {{ member }} <br>
            </span>
        </div>
    </section>
</template>
<style scoped>
    section{
        position: relative;
    }

    button
    {
        width: 100%;
        height: 119px;
        margin-top: 37px;
    }

    .CanvasCommunicate-date-header
    {
        border-top: 1px solid #ddd; 
        margin: 20px 10px 0px 10px;
    }
    .CanvasCommunicate-date-header p
    {
        text-align: center; 
        font-weight: bold;
    }

    #CanvasCommunicate-member-area
    {
        line-height: 30px;
    }
</style>