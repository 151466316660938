<script>
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
export default {
    components:{Form, PopUp },
    props: ['page'],
    data() {
        return {
            showInstuctions: false,
            userInstruction: null,
            form: [                   
                { sections: [
                    { sectionName: 'Hjälptext', classes: "g-100", inputs: [
                        { type: 'textEditor', prop: 'text', title: 'text', classes: "g-100", required: true },                        
                        ]
                    }]
                }]
            
        }
    },
    created() {     
        this.$cache.createCache('userInstructions', 'user-instructions/');
    },
    methods:
    {
        edit()
        {
            this.userInstruction = this.$store.state.userInstructions.find(x => x.page == this.page);

            if(this.userInstruction == null)
            {
                this.userInstruction = { text:'', page: this.page };
            }
        },
        updateInstructions()
        {
            this.$cache.updateCache('userInstructions', 'user-instructions/');
            this.userInstruction = null;
        },
        openInstructions()
        {
            this.showInstuctions = !this.showInstuctions;

            if(this.showInstuctions)
            {
                $(".opacity-admin-bg").addClass("opacity-admin-bg-show-instructions");
            } else
            {
                $(".opacity-admin-bg").removeClass("opacity-admin-bg-show-instructions");
            }
        }
    },
    computed: {
        text() {  
            if(this.$store.state.userInstructions == null)
            {
                return '';
            }

            var userInstruction = this.$store.state.userInstructions.find(x => x.page == this.page);
            
            return userInstruction == null ? '' : userInstruction.text;
        },
        user() {return this.$store.state.user; }       
    }
}
</script>
<template>
    <button class="btn" v-show="showInstuctions" v-tooltip="'Dölj hjälptext'" @click="openInstructions">
        <i class="fa-solid fa-info"></i>    
    </button>
    <button class="btn" v-show="!showInstuctions" v-tooltip="'Visa hjälptext'" @click="openInstructions">
        <i class="fa-solid fa-info"></i>    
    </button>
    <div :class="showInstuctions ? 'UserInstructions-area-visible' : ''" class="UserInstructions-area" :style="{'height': this.$windowHeight - 120 + 'px'}">       
        <i v-if="user.user_level == 'SamfallifyCrew'" class="fa-solid fa-pen" style="color: black" @click="edit"></i> 
        <div v-html="text"></div>
    </div>
    <div v-if="userInstruction != null">
        <PopUp>
            <Form :form="form" :item="userInstruction" :url="'user-instructions/'" :getItemFunction="updateInstructions" :inEditMode="true"></Form>
        </PopUp>
    </div>
</template>
<style scoped>
i{
    display: inline-block;
    cursor: pointer; 
    color: white;      
}

button{
    padding: 5px 15px;
    background-color: #90d5ac !important;
    border-color: #5ab981;
    
}

.UserInstructions-area
{
    position: fixed;
    width: 360px;
    top: 76px;
    background-color: rgba(250, 233, 197, 0.95);
    right: -400px;
    padding: 15px 20px;
    transition: ease-out 0.4s;
    font-size: 14px;
    overflow: auto;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.05);    
}

.UserInstructions-area-visible
{ 
    right: 22px;
    transition: ease-in 0.4s;
}
    


</style>