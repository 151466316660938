<script>
import AdminLink from '../components/AdminLink.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import Notification from '@/components/Notification.vue'

export default {
    components:{ AdminLink, Form, PopUp, Notification },   
    data() {
        return {  
            forum: {},
            forumPosts: [],
            form: [                   
                    { sections: [
                        { sectionName: 'Skapa ' +  this.$store.state.settings.ForumPost.singular, classes: "g-100", inputs: [
                            { type: 'text', prop: 'subject', title: 'Titel', classes: "g-100", required: true }, 
                            { type: 'textEditor', prop: 'body', title: 'Text', classes: "g-100", required: true },   
                            ]
                        }]
                    }],
            newThread: null
        }   
    },
    methods:
    {
        getForum()
        {
            var self = this;
            this.$communicator.get('forums/' + this.$route.params.id)
                .then(function(data)
                {
                    self.forum = data.forum;
                    self.forumPosts = data.posts;
                    self.newThread = null;
                    self.$cache.updateCache('forums', 'forums/') 
                })
                .fail(function(error) {
                    self.$apiErrorhandler.handleErrors(error, self.$route.fullPath) 
                });   
        },
        add()
        {
            this.newThread = { id: 0, subject: '', body: '', forum_id: this.$route.params.id, is_work_group: this.forum.work_group_id > 0, is_board: this.forum.category == 'Styrelsen' };
        },
        closePopUp() { 
            this.newThread = null;
        }
    },
    created(){
        this.getForum();
    },
    computed:
    {
        posts()
        {
            var posts = [];

            this.forumPosts.forEach(x => {
                var replyPosts = this.forumPosts.filter(y => y.reply_to_post_id == x.id);
                posts.push({
                id: x.id,
                reply_to_post_id: x.reply_to_post_id,
                subject: x.subject,
                createdBy: x.created_by_user.full_name,
                replys: replyPosts.length,
                last_reply: replyPosts.length > 0 ? replyPosts[0].created_by_user.full_name + ' ' + this.$formatters.date(replyPosts[0].created_date, 'dateAndTime')  : ""
                })
            });

            return posts;
        },
        notifications() {  
            return this.$store.state.notifications != null ? this.$store.state.notifications : []; 
        }   
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-white-bg">
            <section>
                <div class="breadcrumbs">
                    <router-link :to="{ name: 'forum'}">
                        {{this.$store.state.settings.Forum.plural}}
                    </router-link> 
                    &nbsp; >>  &nbsp;
                    <b>{{ forum.category }}</b>
                </div>
                <h2>{{ forum.category }}</h2>
                <div class="margin-top-25">
                    <div v-for="(post,index) in posts.filter(x => x.reply_to_post_id == null)" class="position-relative">
                        <Notification :level="'forumThread'" :levelName="post.id" :notifications="notifications" :color="'#d98df6'" :left="'-10px'" :top="'0px'"></Notification>                       
                        <router-link :to="{ name: 'forumThread', params: { id: forum.id, threadId: post.id }}">
                            <div class="forum" v-hover>
                                <div class="float-left">
                                    <b>{{ post.subject }}</b>
                                    <br>
                                    av {{ post.createdBy }}
                                </div>
                                <div class="no-of-posts">
                                    {{ post.replys }} svar<br>
                                    <span v-if="post.replys > 0">Senast svarat av {{ post.last_reply }}</span>
                                </div>
                            </div>
                        </router-link>                  
                    </div>
                    <div v-if="!posts.some(x => x.reply_to_post_id == null)">
                        Inga {{this.$store.state.settings.ForumPost.plural}} än så länge :(
                        </div>
                    </div>
                    <button class="btn float-right mobile-100w-btn margin-top-15" @click="add">Lägg till nytt {{this.$store.state.settings.ForumPost.singular}} </button>
            </section>   
            <div v-if="newThread != null">
                    <PopUp :closePopup="closePopUp">
                    <Form :form="form" :item="newThread" :url="'posts/'" :getItemFunction="getForum" :inEditMode="true"></Form>
                    </PopUp>
                </div>      
        </main>
    </div>
</template>
<style scoped>
    .forum{
        border-bottom:1px solid #ddd;
        overflow: auto;
        padding:10px 15px;
        background: white;
    }

    .no-of-posts{
        float: right;
        text-align: right;
        color: black;
        font-size: 11pt;
        line-height: 20px;
    }

    .forum a { font-weight: bold;}

    .latest-post
    {
        padding-top:4px;
        font-size: 14px;
    }
</style>