<script>
import config from "@/helpers/config"
export default {
    props: ['model', 'prop'],
    data(){
        return {
           
        }
    },
    created()
    {
        this.$cache.createCache('images', 'images/');
    },
    methods:
    {
        selectImage(selectedImage, image_ids)
        {
            if(image_ids.some(x => x == selectedImage.id))
            {
                image_ids.splice(image_ids.indexOf(selectedImage.id), 1)
                
            } else{
                image_ids.push(selectedImage.id);
            }
        },
    },
    computed:
    {
        images() {  return this.$store.state.images != null ? this.$store.state.images : []  },
        serverUrl() { return config.urlToServer }       
    }
}
</script>
<template>
    <div class="SelectImage-wrapper">
        <div v-for="image in images" class="SelectImages-image" @click="model[prop] = image.id">
            <div v-if="model[prop] == image.id" class="SelectImages-image-selected">
                <span class="font-color">vald</span>
            </div>
            <img :src="serverUrl + 'uploads/'+ image.customer_id+'/images/thumbnails/'+ image.name" />                
        </div>
    </div>
</template>
<style scoped>
    .SelectImage-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .SelectImages-image
    {
        height: 130px;
        position: relative;
        cursor: pointer;
    }
    
    .SelectImages-image-selected
    {
        position: absolute;
        background-color: rgba(255,255,255,0.5);
        width: 100%;
        height: 100%;
        font-size: 14pt;
        line-height: 122px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;

    }

    .SelectImages-image-selected span
    {
        display: inline;
        margin: auto;
        background-color: white;
        padding: 5px;
        border-radius: 3px;
    }
    
    .SelectImages-image img
    {
       height: 100%;
    }
    
    .SelectImages-image img:hover
    {
       height: 100%;
    }
</style>