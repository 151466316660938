<script>
import AdminLink from '../components/AdminLink.vue'
export default {
    components:{ AdminLink},
    created() {
        this.$cache.createCache('forums', 'forums/') 
    },
    data() {
        return {          
          
        }
    
        },
        computed: {
            forums() {  return this.$store.state.forums == null ? [] : this.$store.state.forums; },
            customer() {  return this.$store.state.customer == null ? {} : this.$store.state.customer; }
        }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-white-bg">
            <section>
                <AdminLink :buttonText="'Lägg till/ta bort avdelningar'" :sectionToEdit="'adminForums'"></AdminLink>
                <h2>{{this.$store.state.settings.Forum.singular}}</h2>
                <div class="margin-top-25">
                    <router-link :to="{ name: 'forumCategory', params: { id: forum.id }}" v-for="(forum,index) in forums.filter(x => x.work_group_id == null && x.category != 'Styrelsen')" class="forum" v-hover>
                        <div class="no-of-posts"> {{ forum.posts.filter(x => x.reply_to_post_id == null && x.deleted_by == null).length}} inlägg</div>
                        <div>
                            <b>{{ forum.formatted_category }}</b> {{ forum.description != null ? ' - ' + forum.description : '' }}
                            <div class="latest-post">
                                <span v-if="forum.posts.filter(x => x.reply_to_post_id == null && x.deleted_by == null).length > 0">Senaste inlägg: {{ forum.posts[0].subject }}</span>
                                <span v-else>Tomt på inlägg :(</span>
                            </div>                            
                        </div>
                    </router-link>
                    <div v-if="forums.length == 0">
                        Här var det tomt :(
                    </div>
                </div>
                <div v-if="!customer.disable_board_forum && this.$store.state.settings.BoardMember.enabled">
                    <h2 class="margin-top-25">Kom i kontakt med {{ this.$store.state.settings.BoardMember.singular }}</h2>
                    <div>
                        <router-link :to="{ name: 'forumCategory', params: { id: forum.id }}" v-for="(forum,index) in forums.filter(x => x.category == 'Styrelsen')" class="forum" v-hover>
                            <div class="no-of-posts"> {{ forum.posts.filter(x => x.reply_to_post_id == null && x.deleted_by == null).length}} inlägg</div>
                            <div>
                                <b>{{ forum.formatted_category }}</b> {{ forum.description != null ? ' - ' + forum.description : '' }}
                                <div class="latest-post">
                                    <span v-if="forum.posts.filter(x => x.reply_to_post_id == null && x.deleted_by == null).length > 0">Senaste inlägg: {{ forum.posts[0].subject }}</span>
                                    <span v-else>Tomt på inlägg :(</span>
                                </div>                            
                            </div>
                        </router-link>
                        <div v-if="forums.length == 0">
                            Här var det tomt :(
                        </div>
                    </div>
                </div>
                <div v-if="this.$store.state.settings.WorkGroup.enabled &&forums.filter(x => x.work_group_id != null).length > 0">    
                    <h2 class="margin-top-25">Kom i kontakt med föreningens {{ this.$store.state.settings.WorkGroup.plural }}</h2>
                    <div>
                        <router-link :to="{ name: 'forumCategory', params: { id: forum.id }}" v-for="(forum,index) in forums.filter(x => x.work_group_id != null)" class="forum" v-hover>
                            <div class="no-of-posts"> {{ forum.posts.filter(x => x.reply_to_post_id == null && x.deleted_by == null).length}} inlägg</div>
                            <div>
                                <b>{{ forum.formatted_category }}</b> {{ forum.description != null ? ' - ' + forum.description : '' }}
                                <div class="latest-post">
                                    <span v-if="forum.posts.filter(x => x.reply_to_post_id == null && x.deleted_by == null).length > 0">Senaste inlägg: {{ forum.posts[0].subject }}</span>
                                    <span v-else>Tomt på {{ this.$store.state.settings.ForumPost.plural }} :(</span>
                                </div>                            
                            </div>
                        </router-link>                 
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<style scoped>
    .forum{
        border-bottom:1px solid #ddd;
        overflow: auto;
        padding:10px;
        background: white;
        display: block;
        text-decoration: none;
    }

    .no-of-posts{
        float: right;
    }

    .latest-post
    {
        padding-top:4px;
        font-size: 14px;
    }
</style>