<script>
import Tabs from '@/components/Tabs.vue'
import CanvasDocuments from '@/components/canvas/CanvasDocuments.vue'
import CanvasCommunicate from '@/components/canvas/CanvasCommunicate.vue'
import CanvasMembers from '@/components/canvas/CanvasMembers.vue'
import CanvasForum from '@/components/canvas/CanvasForum.vue'
export default {
    components: { Tabs, CanvasDocuments, CanvasCommunicate, CanvasMembers, CanvasForum },
    data() {
        return {
            selectedTab: 'Kommunicera'
        };
    },
    created() {      
        this.$cache.createCache('workGroups', 'work-groups/');
        this.$cache.createCache('forums', 'forums/');
    },
    computed:
    {
        workGroup() {  return this.$store.state.workGroups != null ? this.$store.state.workGroups.find(x => x.id == this.$route.params.id) : null },
        hasForum() {  return this.$store.state.forums != null ? this.$store.state.forums.some(x => x.work_group_id == this.workGroup.id) : false },
        notifications() {   return this.$store.state.notifications != null ? this.$store.state.notifications : []; },
        tabs() { return this.hasForum && this.$store.state.settings.Forum.enabled ? ['Kommunicera', 'Deltagare', this.$store.state.settings.Forum.singular, 'Filer'] : ['Kommunicera', 'Deltagare', 'Filer']; },
        members() { return this.workGroup.members.map(x => x.user.full_name) }
    }
};
</script>
<template>
    <div v-fade-in v-if="workGroup != null">
        <main class="opacity-white-bg">
            <h2>{{ workGroup.name }}</h2>
            <Tabs :tabs="tabs" v-model:selectedTab="selectedTab" :notifications="notifications.filter(x => x.level.menu == workGroup.name)">               
            </Tabs>     
            <div v-show="selectedTab == 'Kommunicera'">
                <CanvasCommunicate :workGroupId="workGroup.id" :members="members"></CanvasCommunicate>
            </div>
            <div v-show="selectedTab == 'Deltagare'">
                <CanvasMembers :members="members"></CanvasMembers>
            </div>
            <div v-if="selectedTab == this.$store.state.settings.Forum.singular && this.$store.state.settings.Forum.enabled">
                <CanvasForum :workGroupId="workGroup.id"></CanvasForum>
            </div>
            <div v-if="selectedTab == 'Filer'">
              <CanvasDocuments :workGroupId="workGroup.id"></CanvasDocuments>
            </div>        
        </main>
    </div>

</template>
<style scoped>
    .Community-tabs-wrapper
    {
        width:100%;
        border-bottom: 1px solid black;
        margin-bottom: 15px;

    }

    .Community-tabs-wrapper h2 {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        margin: 0px;
    }
</style>
