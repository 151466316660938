import store from '../store'
import communicator from './communicator'
export default {
  date(date, type)
  {
    var splittedDate = date.split('T');
    var months = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','oktober', 'november', 'december']
    var shortMonths = ['jan', 'feb', 'mars', 'apr', 'maj', 'jun', 'jul', 'aug','okt', 'nov', 'dec']
    var days = ['måndagen', 'tisdagen', 'onsdagen', 'torsdagen', 'fredagen', 'lördagen', 'söndagen']

    switch(type)
    {
      case 'date':    
        return splittedDate[0];
      case 'dateAndTime':
        return splittedDate[0] + ' ' + splittedDate[1].substring(0,5);
      case 'time':
          return splittedDate[1].substring(0,5);
      case 'dateInLetters':
        var date = splittedDate[0];
        var dayInWeek = new Date(date).getDay();     
        var monthNumber = parseInt(date.substring(5,7));
        return days[dayInWeek-1] + ' den '+ date.substring(8,10)+' ' + months[monthNumber-1] + ' ' + date.substring(0,4);
      case 'monthDateInLetters':
        var date = splittedDate[0];
        var monthNumber = parseInt(date.substring(5,7));
        return parseInt(date.substring(8,10))+' ' + months[monthNumber-1];
      case 'month':
        var date = splittedDate[0];
        var monthNumber = parseInt(date.substring(5,7));
        return months[monthNumber-1];     
      case 'shortMonth':
        var date = splittedDate[0];
        var monthNumber = parseInt(date.substring(5,7));
        return shortMonths[monthNumber-1];
      case 'dateNumber':
        var date = splittedDate[0];
        return parseInt(date.substring(8,10));
    }    
  },
  age(dateString)
  {
    if(dateString == undefined || dateString == null || dateString == '')
    {
      return '';
    }
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age + ' år';
  },
  address(user)
  {
    var address = [];
    if(user.address_street != null && user.address_number != null)
    {
      address.push(user.address_street + ' ' + user.address_number);
    }

    if(user.address_postal)
    {
      address.push(user.address_postal)
    }

    if(user.address_city)
    {
      address.push(user.address_city)
    }
    return address.join(', ')
  },
  listOfUsers(userIds, users)
  {
    var userNames = [];
    userIds.forEach(function(id) {
        var user = users.find(x => x.id == id);
        userNames.push(user.full_name);
     })

     return userNames.join(', ');
  },
  listOfWorkGroups(workGroupIds, workGroups)
  {
    var workGroupNames = [];
    workGroupIds.forEach(function(id) {
        var workGroup = workGroups.find(x => x.id == id);
        workGroupNames.push(workGroup.name);
     })

     return workGroupNames.join(', ');
  }
}