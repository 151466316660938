<script>
import AdminLink from '../components/AdminLink.vue'
export default {
    components:{ AdminLink },
    computed: {
        rulesOfProcedure() {  return this.$store.state.rulesOfProcedure }
    }
}
</script>
<template>
    <div class="overflow-auto">
        <AdminLink :buttonText="'Lägg till/ändra ' + this.$store.state.settings.RuleOfProcedure.plural" :sectionToEdit="'adminRulesOfProcedure'"></AdminLink>  
        <h2>{{this.$store.state.settings.RuleOfProcedure.plural}}</h2>
        <ul>
            <li v-for="ruleOfProcedure in rulesOfProcedure">
                <span v-html="ruleOfProcedure.rule_of_procedure"></span>
            </li>
        </ul>
    </div>       
</template>

<style scoped>
    li{
        padding: 7px 0px;
    }
</style>