import { createRouter, createWebHistory } from "vue-router";

import Home from '../views/Home.vue'
import Community from '../views/Community.vue'
import NotFound from '../views/NotFound.vue'
import Forum from '../views/Forum.vue'
import ForumCategory from '../views/ForumCategory.vue'
import ForumThread from '../views/ForumThread.vue'
import LogIn from '../views/LogIn.vue'
import LogOut from '../views/LogOut.vue'
import UserSettings from '../views/UserSettings.vue'
import CanvasBoard from '../views/CanvasBoard.vue'
import CanvasWorkGroup from '../views/CanvasWorkGroup.vue'
import Bookings from '../views/Bookings.vue'
import BookingList from '../views/BookingList.vue'
import BookingHoursAdd from '../views/BookingHoursAdd.vue'
import BookingDaysAdd from '../views/BookingDaysAdd.vue'
import Calendar from '../views/Calendar.vue'
import Invitations from '../views/Invitations.vue'
import Invitation from '../views/Invitation.vue'
import ImageGallery from '../views/ImageGallery.vue'
import Admin from '../views/Admin/AdminStart.vue'
import AdminNews from '../views/Admin/AdminNews.vue'
import AdminForums from '../views/Admin/AdminForums.vue'
import AdminSettings from '../views/Admin/AdminSettings.vue'
import AdminDocuments from '../views/Admin/AdminDocuments.vue'
import AdminDocumentCategories from '../views/Admin/AdminDocumentCategories.vue'
import AdminBoardMembers from '../views/Admin/AdminBoardMembers.vue'
import AdminStatutes from '../views/Admin/AdminStatutes.vue'
import AdminImageVault from '../views/Admin/AdminImageVault.vue'
import AdminRulesOfProcedure from '../views/Admin/AdminRulesOfProcedure.vue'
import AdminMembers from '../views/Admin/AdminMembers.vue'
import AdminBookings from '../views/Admin/AdminBookings.vue'
import AdminBookingsEdit from '../views/Admin/AdminBookingsEdit.vue'
import UserMessages from '../views/UserMessages.vue'
import AdminWorkGroups from '../views/Admin/AdminWorkGroups.vue'
import AdminInvitations from '../views/Admin/AdminInvitations.vue'
import AdminInvitationsAdd from '../views/Admin/AdminInvitationsAdd.vue'
import AdminInvitationsView from '../views/Admin/AdminInvitationsView.vue'
import AdminAccount from '../views/Admin/AdminAccount.vue'
import AdminPublic from '../views/Admin/Public/AdminPublic.vue'

const routes = [   
    {
        path:'/login',
        component: LogIn,
        name: 'login'
    },
    {
        path:'/logout',
        component: LogOut,
        name: 'logout'
    },
    {
        path:'/booking',
        component: Bookings,
        name: 'bookings'
    },
    {
        path:'/add-days-booking/:id',
        component: BookingDaysAdd,
        name: 'bookingDaysAdd'
    },
    {
        path:'/add-hours-booking/:id',
        component: BookingHoursAdd,
        name: 'bookingHoursAdd'
    },
    {
        path:'/booking-list',
        component: BookingList,
        name: 'bookingList'
    },
    {
        path:'/calendar',
        component: Calendar,
        name: 'calendar'
    },
    {
        path:'/forum',
        component: Forum,
        name: 'forum'
    },
    {
        path:'/forum/:id',
        component: ForumCategory,
        name: 'forumCategory'
    },
    {
        path:'/forum/:id/thread/:threadId',
        component: ForumThread,
        name: 'forumThread'
    },
    {
        path:'/community/',
        component: Community,
        name: 'community'
    },
    {
        path:'/image-gallery',
        component: ImageGallery,
        name: 'imageGallery'
    },
    {
        path:'/invitation',
        component: Invitations,
        name: 'invitations'
    },
    {
        path:'/invitation/:id',
        component: Invitation,
        name: 'invitation'
    },
    {
        path:'/user-settings',
        component: UserSettings,
        name: 'userSettings'
    },
    {
        path:'/canvas-board/',
        component: CanvasBoard,
        name: 'canvasBoard'
    },
    {
        path:'/canvas/:id',
        component: CanvasWorkGroup,
        name: 'canvasWorkGroup'
    },
    {
        path:'/admin',
        component: Admin,
        name: 'admin'
    },
    {
        path:'/admin/news',
        component: AdminNews,
        name: 'adminNews'
    },
    {
        path:'/admin/invitations',
        component: AdminInvitations,
        name: 'adminInvitations'
    },
    {
        path:'/admin/documents/:id',
        component: AdminDocuments,
        name: 'adminDocuments'
    },
    {
        path:'/admin/documents',
        component: AdminDocumentCategories,
        name: 'adminDocumentCategories'
    },
    {
        path:'/admin/settings',
        component: AdminSettings,
        name: 'adminSettings'
    },
    {
        path:'/admin/invitations/add',
        component: AdminInvitationsAdd,
        name: 'adminInvitationsAdd'
    },     
    {
        path:'/admin/invitations/:id/view',
        component: AdminInvitationsView,
        name: 'adminInvitationsView'
    },     
    {
        path:'/admin/invitations/:id',
        component: AdminInvitationsAdd,
        name: 'adminInvitationsEdit'
    },     
    {
        path:'/admin/forums',
        component: AdminForums,
        name: 'adminForums'
    },
    {
        path:'/admin/account',
        component: AdminAccount,
        name: 'adminAccount'
    },
    {
        path:'/admin/image-vault',
        component: AdminImageVault,
        name: 'adminImageVault'
    },
    {
        path:'/admin/public-site',
        component: AdminPublic,
        name: 'adminPublic'
    },
    {
        path:'/admin/board-members',
        component: AdminBoardMembers,
        name: 'adminBoardMembers'
    },
    {
        path:'/admin/work-groups',
        component: AdminWorkGroups,
        name: 'adminWorkGroups'
    },
    {
        path:'/admin/members',
        component: AdminMembers,
        name: 'adminMembers'
    },    
    {
        path:'/admin/bookings',
        component: AdminBookings,
        name: 'adminBookings'
    },
    {
        path:'/admin/bookings/:id',
        component: AdminBookingsEdit,
        name: 'adminBookingsEdit'
    },
    {
        path:'/admin/bookings/add',
        component: AdminBookingsEdit,
        name: 'adminBookingsAdd'
    },
    {
        path:'/admin/statutes',
        component: AdminStatutes,
        name: 'adminStatutes'
    },
    {
        path:'/admin/rules-of-procedure',
        component: AdminRulesOfProcedure,
        name: 'adminRulesOfProcedure'
    },
    {
        path:'/user-messages',
        component: UserMessages,
        name: 'userMessages'
    },
    {
        path: '/',
        component: Home,
        name: 'home'
    },    
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }

]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
      },

})

export default router
