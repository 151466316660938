<script>
import List from '../components/List/List.vue'
import UserMessageSender from '@/components/UserMessageSender.vue'
export default {
    components: { List, UserMessageSender },
    created() { 
        var self = this;
        this.$communicator.get('user-sent-messages/') 
            .then(function(data)
            {
                self.sentMessages = data;
            })
            .fail(function(error) { 
                self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)              
            }) 
    },
    data() {
        return {  
            message: null,
            sentMessages: null,
            replyMessage: null,
            openingMessage: false,
            showSentTab: false,
        }
    },
    computed:
    {
        userMessages() {  
            if(this.$store.state.userMessages != null)
            {
                
                var messages = this.$store.state.userMessages.map(x => ({
                id: x.id,
                to_user_id: x.to_user_id,
                is_read: x.is_read,
                created_by_name: x.created_by_user.full_name,
                created_date: x.created_date,
                created_by_user: x.created_by_user,
                subject: x.subject,
                message: x.message,
                replys: x.replys,
                last_reply: x.replys.length > 0 ? x.replys.filter(y => y.created_by != this.user.id).slice(-1)[0] : null
                }));    
                
                // if(this.message != null)
                // {
                //     this.message = messages.find(x => x.id == this.message.id);
                // }
                
                return messages;         
            }
            else { 
                return [];
            }
        },       
        user() {  return this.$store.state.user; }        
    },
    methods:
    {
        selectMessage(message){
            var self = this;
            console.log(message);
            if(!message.is_read && message.created_by_user.user_id != this.user.id)
            {
                message.is_read = true;
                this.$communicator.post(('read-user-message/' + message.id + '/'))
                .then(function(data)
                {
                   
                })
                .fail(function(error) {                  
                })              
            }

            if(message.replys.some(x => !x.is_read))
            {
                message.last_reply.is_read = true;
                this.$communicator.post(('read-user-reply-message/' + message.id + '/'))
                    .then(function(data)
                    {
                    
                    })
                    .fail(function(error) {                  
                    })  
            }

            this.message = message;
            this.openingMessage = true;
            setTimeout(() => {           
                self.openingMessage = false;
            }, 50);
        },
        switchTabs(isSentTab)
        {
            this.message = null;
            this.showSentTab = isSentTab;

        },
        reply()
        {
            this.replyMessage = { id: 0, reply_to_id: this.message.id, subject: '', message: '', toUserName: this.message.created_by_name };
        },
        closeUserMessageSender()
        {
            this.$cache.updateCache('userMessages', 'user-messages/');           
            this.replyMessage = null;
        }
    }
}
</script>
<template>
    <main class="template-two-cols-left-30-percent" v-fade-in>
        <div class="row margin-bottom-15">
            <div class="g-100 UserMessages-buttons">
                <button class="btn btn-mail-box" @click="switchTabs(false)" :class="[showSentTab ? 'btn-gray' : '']">Inkorg</button>
                <button class="btn btn-mail-box"  @click="switchTabs(true)"  :class="[!showSentTab ? 'btn-gray' : '']">Skickat</button>
            </div>          
        </div>      
        <section v-if="(this.$isMobile && message == null) || !this.$isMobile"> 
            <div v-if="!showSentTab">         
                <div v-for="message in userMessages" @click="selectMessage(message)">
                    <div v-if="message.to_user_id == user.id" class="UserMessage-message-item" :style="{ 'font-weight': message.is_read ? 'normal' : 'bold' }" >
                        <div class="float-right" v-date="{ date: message.created_date, type: 'monthDateInLetters', displayTodayText: true }"></div>
                        <span class="font-color">                   
                                {{message.created_by_name }}
                        </span>
                        <p>{{ message.subject }}</p>
                    </div>
                    <div v-if="message.last_reply != undefined" class="UserMessage-message-item" :style="{ 'font-weight': message.last_reply.is_read ? 'normal' : 'bold' }" >
                        <div class="float-right" v-date="{ date: message.last_reply.created_date, type: 'monthDateInLetters', displayTodayText: true }"></div>
                        <span class="font-color">                   
                                {{message.last_reply.created_by_user.full_name}}
                        </span>
                        <p>{{ message.last_reply.subject }}</p>
                    </div>
                </div> 
                <div v-if="userMessages != null && userMessages.length == 0">
                    Du har inga meddelanden. 
                </div> 
            </div>        
            <div v-else>         
                <div v-for="message in sentMessages" class="UserMessage-message-item" @click="selectMessage(message)">
                    <div class="float-right" v-date="{ date: message.created_date, type: 'monthDateInLetters', displayTodayText: true }"></div>
                    <span class="font-color">{{message.to_user.full_name}}
                    </span>
                    <p>{{ message.subject }}</p>
                </div> 
                <div v-if="sentMessages != null && sentMessages.length == 0">
                    Du har inte skickat några meddelanden. 
                </div> 
            </div>        
        </section>  
        <aside v-if="(this.$isMobile && message != null) || !this.$isMobile" style="margin-top: -45px; margin-left: -35px;">
            <div v-if="message != null" class="UserMessage-message-selected">
                <h4>{{ message.subject }}</h4>
                <div style="font-size: 14px">
                    <span v-if="!showSentTab">
                        Från: {{message.created_by_name }}
                    </span>
                    <br>
                    Skickat: <span v-if="!openingMessage" v-date="{ date: message.created_date, type: 'dateAndTime' }"></span>
                </div>
                <br>
                <p class="UserMessages-message" v-html="message.message"></p>
                <div v-if="!showSentTab" class="margin-top-15">
                    <div v-for="reply in message.replys"> 
                        <div class="overflow-auto">                  
                            <i v-if="reply.created_by == user.id" class="UserMessages-own-reply-i">Du svarade den <span v-date="{ date: reply.created_date, type: 'dateAndTime' }"></span>:</i>
                            <i v-if="reply.created_by != user.id" class="UserMessages-reply-i">{{reply.created_by_user.full_name }} svarade den <span v-date="{ date: reply.created_date, type: 'dateAndTime' }"></span>:</i>
                        </div>
                        <div class="overflow-auto">
                            <div  class="UserMessages-bubble wow-color" :class="[reply.created_by == user.id ? 'UserMessages-bubble-own-mess' : '']">
                                <h4>{{ reply.subject }}</h4>
                                <p v-html="reply.message"></p>
                            </div>
                        </div>
                    </div>
                    <button class="btn float-right" @click="reply">Svara</button>
                    <button class="btn float-right btn-gray" @click="message = null">Tillbaka</button>
                </div>
            </div>
            <div v-else class="UserMessage-no-message-selected">
                <i class="fa-regular fa-message"></i>
                Välj ett meddelande till vänster
            </div>
        </aside>      
    </main>
    <div v-if="replyMessage != null">
        <UserMessageSender :userMessage="replyMessage" :closeUserMessageSender="closeUserMessageSender" :isReply="true"></UserMessageSender>
    </div> 
</template>
<style scoped>
    h4{
        margin-top: 10px;
        margin-bottom: 7px;
    }

    .btn-mail-box
    {
        font-size: 12pt;
        padding: 8px 19px;
        border-radius: 4px;
    }

    .UserMessages-own-reply-i{      
        width: 100%; 
        display: block;
        font-size: 14px;
        text-align: right;
    }

    .UserMessages-message
    {
        padding: 0px 0px 20px 0px;
        border-bottom: 1px solid #ccc;
    }
    
    .UserMessages-reply-i{
        text-align: left; 
        width: 100%; 
        display: block;
        font-size: 14px;
    }

    p{
        margin: 2px;
    }
    .UserMessages-bubble
    {
        width: 70%;
        float:left;
        border: 1px solid #ddd;
        margin: 10px 0px;
        padding: 0px 10px 10px 10px;
        border-radius: 10px;
    }

    .UserMessages-bubble-own-mess
    {
        float:right;
        margin: 10px 0px 10px 100px;
    }

    .UserMessage-message-item
    {
        cursor: pointer;
        padding:10px;
        border-bottom: 1px solid #ddd;
    }
  
    .UserMessage-no-message-selected
    {
        text-align: center;
        padding: 100px 0px;
    }
   
    .UserMessage-no-message-selected i
    {
        display: block;
        font-size: 60pt;
        color: #ddd;
        padding: 20px;
    }

    .UserMessage-message-selected
    {
        padding: 0px 10px;
    }

    @media only screen and (max-width: 1000px) {
        .UserMessages-buttons
        {
            margin-left: 10px;
        }

        main
        {
            margin-top: 75px !important;
            overflow: hidden;
        }
    }
</style>