<script>
import AdminLink from '../components/AdminLink.vue'
import config from "@/helpers/config"
import ImageGalleryPopUp from '@/components/ImageGalleryPopUp.vue'
export default {
    components:{ AdminLink, ImageGalleryPopUp },
    created() {
        this.$cache.createCache('images', 'images/');
    },
    data() {
        return {   
            selectedImage: { image: null }
        } 
    },
    computed: {
        url() { return config.urlToServer },
        images() {  return this.$store.state.images != null ? this.$store.state.images : []  }
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-white-bg">
            <section>
                <AdminLink :buttonText="'Lägg till/ta bort bilder'" :sectionToEdit="'adminImageVault'"></AdminLink>
                <h2>{{this.$store.state.settings.ImageGallery.singular}}</h2>
                <div class="ImageGallery-img-area">
                    <div v-for="image in images.filter(x => x.include_in_image_gallery)">              
                        <img :src="url + 'uploads/'+ image.customer_id+'/images/thumbnails/'+ image.name" @click="selectedImage.image = image" />               
                    </div>
                </div>
               
                <div v-if="selectedImage.image != null">
                    <ImageGalleryPopUp :selectedImage="selectedImage" :images="images">
                    </ImageGalleryPopUp>
                </div>
            </section>
        </main>
    </div>
</template>
<style scoped>
.ImageGallery-img-area
{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.ImageGallery-img-area img
{
    max-height: 150px;
    padding:5px;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .ImageGallery-img-area
    {
    width: calc(100% - 10px);
    overflow: auto;
    max-height: none;
    }
}
</style>