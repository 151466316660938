<script>
import AdminLink from '../components/AdminLink.vue'
export default {
    components:{ AdminLink },
    computed: {
        statutes() {  return this.$store.state.statutes }
    }
}
</script>
<template>
    <div class="overflow-auto">
        <AdminLink :buttonText="'Lägg till/ändra ' + this.$store.state.settings.Statute.plural" :sectionToEdit="'adminStatutes'"></AdminLink>  
        <h2>{{this.$store.state.settings.Statute.plural}}</h2>
        <ul>
            <li v-for="statute in statutes">
                 <span v-html="statute.statute"></span>
            </li>
        </ul>
    </div>       
</template>

<style scoped>
    li{
        padding: 7px 0px;
    }
</style>