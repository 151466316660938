<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'

export default {
    components:{ List, Form, PopUp,  },
    props: ['selectedBookingItem', 'closeReoccuringBookning'],
    data() {
        return {                        
            reoccuringBooking: null,         
            daysInWeek: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
            form: [                   
                { sections: [{ sectionName: 'Beskrivning', classes: "g-100", inputs: [
                        { type: 'text', prop: 'description', title: 'Beskrivningen kommer visas i kalendern (går att lämna tom).', classes: "g-100" },                                
                    ]
                },{ sectionName: 'Den återkommande bokningens startdatum och slutdatum', classes: "g-100", inputs: [
                        { type: 'date', prop: 'start_date', startWithDateNow: true, title: 'Startdatum', classes: "g-50", required: true }, 
                        { type: 'date', prop: 'end_date', startWithDateNow: true, title: 'Slutdatum', classes: "g-50", required: true }, 
                    ]
                },  { sectionName: 'Bokningens start och sluttid', classes: "g-100", inputs: [
                    { type: 'select', prop: 'start_time', title: 'Från klockan', classes: "g-50", required: true, selectItems: 'hours' },                       
                        { type: 'select', prop: 'end_time', title: 'Till klockan', classes: "g-50", required: true, selectItems: 'hours' },                       
                        ]
                    }, 
            ],
            backgroundColor: this.$colors.form.backgroundColor,
            borderColor: this.$colors.form.borderColor
            }],
            columns: [
                { name: 'edit', displayName: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, align:'center', width: 60, hideFilter: true, hideIfEdit: true},                
                { name: 'description', displayName: 'Namn', width: 300, hideFilter: false},
                { name: 'period', displayName: 'Period', width: 200, hideFilter: false},
                { name: 'day_in_week', displayName: 'Veckodag', width: 100, isDay: true },                       
                { name: 'duration', displayName: 'Bokningens längd', width: 200, hideFilter: true},                       
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},         
            ] 
        }
    },   
    methods:
    {
       
        edit(item)
        {
            this.reoccuringBooking = item;
        },             
        add()
        {
            this.reoccuringBooking = { id: 0, start_date: null, end_date: null, day_in_week: null, start_time: null, end_time: null, description: null, booking_item_id: this.selectedBookingItem.id, name: this.selectedBookingItem.name }
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('booking-reoccuring/' + id + '/delete')
            .then(function(data)
            {                 
                self.getBookings();
                message.setSuccessMessage();
            })
            .fail(function(error) {                  
            })  
        },
        getBookings()
        {
            this.reoccuringBooking = null;
            this.$cache.updateCache('availableBookings', 'available-bookings/');          
        },
        getDayInWeek(dateString)
        {
            var day = new Date(dateString).getDay();
            return this.daysInWeek[day];
        }
    }
}
</script>
<template>
    <PopUp :bgColor="'rgb(250, 233, 197)'" v-if="selectedBookingItem != null" class="text-left">
        <div v-if="reoccuringBooking == null">
            <div class="float-right">
                <button class="btn" @click="add">Skapa ny återkommande bokning</button>
            </div>
            <h2>Återkommande bokningar av {{ selectedBookingItem.name }}</h2>
            <List :items="selectedBookingItem.reoccurring_bookings" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :nameOfItems="'tillgängliga bokningar'" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
            <div class="float-right">
                <button class="btn btn-gray" @click="closeReoccuringBookning">Stäng</button>
            </div>
        </div>
        <div v-else>
            <h2> {{ reoccuringBooking.id > 0 ? 'Redigera återkommande bokning' : 'Skapa återkommande bokning' }}</h2> 
            <div class="info-box" v-if="reoccuringBooking.start_date != null && reoccuringBooking.end_date">
                Förklaring: Du har valt en återkommande bokning varje <b>{{ getDayInWeek(reoccuringBooking.start_date) }}</b> mellan <b>{{reoccuringBooking.start_date}}</b> till <b>{{ reoccuringBooking.end_date }}</b><br>
                <span v-if="reoccuringBooking.end_time != null">
                    Varje bokningstillfälle är mellan <b>{{ reoccuringBooking.start_time }} - {{ reoccuringBooking.end_time }}</b><br><br>
                    Klicka på 'spara' om detta stämmer.
                </span>
            </div>
            <Form :form="form" :item="reoccuringBooking" :url="'booking-reoccurring/'" :getItemFunction="getBookings" :inEditMode="true"></Form>
        </div>
    </PopUp>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>